import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { selectUser, selectUserToken } from "../../features/user";
import accountApi from "../../utility/api/accountApi";
import useQuery from "../../utility/useQuery";

const CarregarModulo = () => {
  const token = useSelector(selectUserToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  let query = useQuery();
  let moduloId = query.get("moduloId");

  const fetch = useCallback(async () => {
    if (!moduloId) navigate("/");
    if (!token) navigate("/");

    let result = await accountApi.buscarTokenModulo(
      {
        moduloId,
        loginUrl: user.lembrar,
      },
      token
    );

    if (result.hasError) {
      Swal.fire({
        title: "Algo deu errado!",
        icon: "error",
      });
      navigate("/");
    } else window.location.href = result.data;
  }, [moduloId, navigate, token, user.lembrar]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return <div></div>;
};

export default CarregarModulo;
