import { Validator } from "../../../utility/validations";
import CopyToClipboard from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import Input from "./../../../components/Input";
import * as Tabs from "@radix-ui/react-tabs";
import IconeUsuario from "./../../../assets/svg/usuario.svg";
import IconeEmail from "./../../../assets/svg/email.svg";
import IconeDepartamento from "../../../assets/svg/departamento.svg";
import IconeLogin from "./../../../assets/svg/login.svg";
import IconePassword from "../../../assets/svg/IconPassword.svg";
import IndexFooter from "../../../components/Cadastro/footer/IndexFooter";
import { useEffect, useState } from "react";
import CancelarButton from "../../../components/CancelarButton";
import divisaoApi from "../../../utility/api/divisaoApi";
import departamentoApi from "../../../utility/api/departamentoApi";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../../features/user";
import Dropdown from "../../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import perfilAcessoApi from "../../../utility/api/perfilAcessoApi";
import TempDropdown from "../../../components/Dropdown/TempDropdown";
import _ from "lodash";
import Modal from "../../../components/Modal";
import useRequest, { Status } from "../../../hooks/useRequest";
import { PerfilAcessoTab } from "./PerfilAcessoTab";
import { SCITab } from "./SCITab";
import { MatrizTab } from "./MatrizTab";
import employeeApi from "../../../utility/api/employeeApi"
import { useTranslation } from "react-i18next";

const corpDivisionId = "8923A376-7941-4C64-B0EA-75C546BD6903";

export const Usuario = ({
  title,
  handleSubmit,
  onSubmit,
  register,
  errors,
  setValue,
  resetForm,
  watch,
  getValues,
  password,
  isLoginDisabled,
}) => {
  const { t } = useTranslation();
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const [isVisibleClipboard, setIsVisibleClipboard] = useState(true);
  const [divisoes, setDivisoes] = useState([]);
  const [divisionsProfiles, setDivisionsProfiles] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [isVisiblePerfil, setisVisiblePerfil] = useState(false);
  const [isSCITabVisible, setIsSCITabVisible] = useState(false);
  const [modalDivision, setModalDivision] = useState({
    divisionId: "",
    profiles: [],
  });
 

  const profilesRequest = useRequest(() =>
    perfilAcessoApi.listarDropdownSearch(null, token)
  );
  const divisionsRequest = useRequest(() => divisaoApi.listarDropdown(token));
  const departmentsRequest = useRequest((data) =>
    departamentoApi.listarDropdown(data, token)
  );

  const [employees, setEmployees] = useState([]);
  const employeeRequest = useRequest(() => employeeApi.listarDropdown(null, token));

  useEffect(() => {
    profilesRequest.execute();
    divisionsRequest.execute();
    employeeRequest.execute();
  }, []);
 
  useEffect(() => {
    const divisionId = getValues("divisaoId");
    if (divisionId) {
      setIsSCITabVisible(divisionId.toLowerCase() === corpDivisionId.toLowerCase());
      departmentsRequest.execute({ divisionId });
    }
  }, [getValues("divisaoId")]);

  useEffect(() => {
    if (
      profilesRequest.status === Status.Failed ||
      divisionsRequest.status === Status.Failed ||
      departmentsRequest.status === Status.Failed
    ) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
    }

    if (profilesRequest.status === Status.Success) {
      setProfiles(
        profilesRequest.data.map((item) => ({
          value: item.id,
          label: item.nome,
        }))
      );
    }

    if (divisionsRequest.status === Status.Success) {
      setDivisoes(
        divisionsRequest.data.map((item) => ({
          value: item.id,
          text: item.name,
        }))
      );
    }

    if (departmentsRequest.status === Status.Success) {
      setDepartamentos(
        departmentsRequest.data.map((item) => ({
          value: item.id,
          text: item.name,
        }))
      );
    }
  }, [
    profilesRequest.status,
    divisionsRequest.status,
    departmentsRequest.status

  ]);

  useEffect(() => {
    const selectedDivision = divisoes.find(
      (d) => d.value == getValues("modalDivisionId")
    );
    setModalDivision({
      ...modalDivision,
      divisionId: getValues("modalDivisionId"),
      divisionName: selectedDivision ? selectedDivision.text : "",
    });
  }, [watch("modalDivisionId")]);

  useEffect(() => {
    const _divisionsProfiles = getValues("divisionProfiles");
    setDivisionsProfiles(_divisionsProfiles);
    if (_divisionsProfiles && _divisionsProfiles.profiles) {
      setValue(
        "profiles",
        _divisionsProfiles.profiles.map((p) => p.id)
      );
    }
  }, [watch("divisionProfiles")]);

  function removeDivision(divisionId) {
    _.remove(divisionsProfiles, (dp) => dp.divisionId === divisionId);

    setValue("divisionProfiles", [...divisionsProfiles]);
  }

  useEffect(() => {
    if (employeeRequest.data) {
      setEmployees(
        employeeRequest.data.map((item) => ({
          value: item.id,
          text: item.text
        }))
      );
    }
  }, [employeeRequest.data])


  function TimerClipboard() {
    if (!setTimeout) {
      setIsVisibleClipboard(false);
    } else {
      setTimeout(() => {
        setIsVisibleClipboard(true);
      }, 4000);
      setIsVisibleClipboard(false);
    }
  }

  function withRequestStatus(request, value) {
    const isLoading =
      request.status === Status.Idle || request.status === Status.Loading;
    const hasError = request.status === Status.Failed;
    if (isLoading) {
      return `${t('components.loading')}`;
    }
    if (hasError) {
      return "Erro";
    }
    return value;
  }

  function clearDivisionInsertion() {
    setisVisiblePerfil(false);
    setModalDivision({});
    setValue("modalDivisionId", "");
  }

  function insertDivision(division) {
    clearDivisionInsertion();

    const divisionExists = (divisionsProfiles || []).find(
      (dp) => dp.divisionId === division.divisionId
    );

    if (!divisionExists) {
      const updatedDivisionsProfiles = [...(divisionsProfiles || []), division];
      setDivisionsProfiles(updatedDivisionsProfiles);
      setValue("divisionProfiles", updatedDivisionsProfiles);
    }
  }

  function insertDivisionModalDataIsValid(division) {
    return (
      division.divisionId && division.profiles && division.profiles.length > 0
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-[60px]">
      <div className="pt-24 px-14 flex justify-center">
        <Tabs.Root
          defaultValue="cadastroUsuario"
          orientation="vertical"
          className="w-[70%]"
        >
          <Tabs.List aria-label="tabs example" className="flex gap-2">
            <Tabs.Trigger
              value="cadastroUsuario"
              role="region"
              className={`px-4 py-2 bg-[#CFCFCF] state_active:bg-[#1B2646] rounded-t-md text-sm`}
            >
              {title}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="perfilAcesso"
              className={`px-4 py-2 bg-[#CFCFCF] state_active:bg-[#1B2646] state_active:text-white rounded-t-md text-sm`}
            >
              {t('addOrUpdateUser.tabs.profileAccess.title')}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="matriz"
              className={`px-4 py-2 bg-[#CFCFCF] state_active:bg-[#1B2646] state_active:text-white rounded-t-md text-sm`}
            >
               {t('addOrUpdateUser.tabs.matriz.title')}
            </Tabs.Trigger>
            {isSCITabVisible && (
              <Tabs.Trigger
                value="sci"
                className={`px-4 py-2 bg-[#CFCFCF] state_active:bg-[#1B2646] state_active:text-white rounded-t-md text-sm`}
              >
                SCI
              </Tabs.Trigger>
            )}
            
          </Tabs.List>

          <Tabs.Content
            value="cadastroUsuario"
            className="w-full !bg-transparent"
          >
            <div className="flex bg-white rounded-md border border-[#ECECEC] rounded-tl-none flex-col p-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="grid gap-2">
                  <Input
                    label={t('addOrUpdateUser.tabs.user.fields.name.label')}
                    name="nome"
                    className="text-black"
                    type="text"
                    placeholder={t('addOrUpdateUser.tabs.user.fields.name.placeholder')}
                    register={register}
                    validate={(text) =>
                      new Validator.Builder().required().build().validate(text)
                    }
                    errors={errors}
                    icon={IconeUsuario}
                  />
                  <Input
                    label={t('addOrUpdateUser.tabs.user.fields.email.label')}
                    name="email"
                    className="text-black"
                    type="email"
                    placeholder={t('addOrUpdateUser.tabs.user.fields.email.placeholder')}
                    register={register}
                    validate={(text) =>
                      new Validator.Builder()
                        .validEmail()
                        .build()
                        .validate(text)
                    }
                    errors={errors}
                    icon={IconeEmail}
                  />

                  <Input
                    label={t('addOrUpdateUser.tabs.user.fields.login.label')}
                    name="login"
                    className="text-black"
                    type="text"
                    placeholder={t('addOrUpdateUser.tabs.user.fields.login.placeholder')}
                    register={register}
                    errors={errors}
                    icon={IconeLogin}
                    disabled={isLoginDisabled}
                  />
                </div>

                <div className="grid gap-2">
                  <Dropdown
                    name="divisaoId"
                    defaultText={withRequestStatus(
                      divisionsRequest,
                      `${t('addOrUpdateUser.tabs.user.fields.division.placeholder')}`
                    )}
                    options={divisoes}
                    py="py-[8px]"
                    width={"w-full"}
                    label={t('addOrUpdateUser.tabs.user.fields.division.label')}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    required
                    register={register}
                    errors={errors}
                  />

                  <Dropdown
                    name="departamentos"
                    defaultText={withRequestStatus(
                      departmentsRequest,
                      `${t('addOrUpdateUser.tabs.user.fields.department.placeholder')}`
                    )}
                    options={departamentos}
                    py="py-[8px]"
                    width={"w-full"}
                    label={t('addOrUpdateUser.tabs.user.fields.department.label')}
                    icon={IconeDepartamento}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    required
                    register={register}
                    errors={errors}
                    multiple
                  />

                  {password && (
                    <Input
                      label={t('addOrUpdateUser.tabs.user.fields.password.label')}
                      name="senha"
                      type="text"
                      readonly={true}
                      register={register}
                      errors={errors}
                      icon={IconePassword}
                      placeholder={password ?? "*".repeat(14)}
                      className="text-black"
                      button={
                        password && (
                          <CopyToClipboard text={password}>
                            <button
                              type="button"
                              className="absolute bottom-0 right-0 h-full px-4 flex items-center justify-end bg-[#1D284A] rounded-r text-white text-[14px] hover:bg-[#26345f]"
                              onClick={TimerClipboard}
                            >
                              <span className="pr-2">
                                <span
                                  className={`${isVisibleClipboard ? "flex" : "hidden"
                                    }`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-clipboard"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                  </svg>
                                </span>
                                <span
                                  className={`${isVisibleClipboard ? "hidden" : "flex"
                                    }`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                  </svg>
                                </span>
                              </span>

                              <span>{t('addOrUpdateUser.tabs.user.fields.password.button')}</span>
                            </button>
                          </CopyToClipboard>
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            <IndexFooter
              width={"w-[100%]"}
              className="py-4 flex items-center justify-between px-11"
            >
              <CancelarButton
                voltar={() => navigate("/usuario/listagem")}
                watch={watch}
                getValues={getValues}
                reset={resetForm}
                wide
              />
              <button className="w-[181px] h-[44px] bg-[#187733] text-white rounded-[4px]">
                {t('components.buttons.save')}
              </button>
            </IndexFooter>
          </Tabs.Content>

          <PerfilAcessoTab
            divisionsProfiles={divisionsProfiles}
            profiles={profiles}
            setisVisiblePerfil={setisVisiblePerfil}
            removeDivision={removeDivision}
            setValue={setValue}
          />

          {isSCITabVisible && (
            <SCITab
              divisions={divisoes}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              errors={errors}
            />
          )}

          <MatrizTab
            employees={employees}
            register={register}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            errors={errors}
          />
        </Tabs.Root>
      </div>
      {isVisiblePerfil && (
        <Modal
          setModal={setisVisiblePerfil}
          title={t('addOrUpdateUser.modalProfileAssociation.title')}
          footer={
            <div className="w-full flex justify-between">
              <button
                className="w-[181px] h-[44px] bg-[#747474] text-white rounded-[4px]"
                onClick={clearDivisionInsertion}
              >
                {t('addOrUpdateUser.modalProfileAssociation.buttons.cancel')}
              </button>
              {insertDivisionModalDataIsValid(modalDivision) && (
                <button
                  type="button"
                  className="w-[181px] h-[44px] bg-[#187733] text-white rounded-[4px]"
                  onClick={() => insertDivision(modalDivision)}
                >
                  {t('addOrUpdateUser.modalProfileAssociation.buttons.add')}
                </button>
              )}
            </div>
          }
        >
          <div className="w-full flex items-center justify-center h-full">
            <div className="w-[80%]">
              <Dropdown
                name="modalDivisionId"
                defaultText={t('addOrUpdateUser.modalProfileAssociation.fields.division.placeholder')}
                options={divisoes}
                label={t('addOrUpdateUser.modalProfileAssociation.fields.division.label')}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
                required
                register={register}
                errors={errors}
              />

              <div className="mt-4">
                <div>
                  <label className="text-[#8A92A6] text-[12px]">
                  {t('addOrUpdateUser.modalProfileAssociation.fields.profileAccess.label')}
                  </label>
                  <TempDropdown
                    placeholder={t('addOrUpdateUser.modalProfileAssociation.fields.profileAccess.placeholder')}
                    options={profiles}
                    onChange={(newValue) => {
                      setModalDivision({
                        ...modalDivision,
                        profiles: newValue.map((p) => ({
                          id: p.value,
                          name: p.label,
                        })),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </form>
  );
};
