import { useCallback, useEffect, useRef, useState } from "react";
import { dropdownPosition } from ".";
import Input from "../Input";
import { useClick } from "./useClick";
import { useTranslation } from "react-i18next";


const DropdownSingle = ({
  defaultText,
  options,
  name,
  register,
  onChange,
  setValue,
  watch,
  py = "py-2",
  width,
  label,
  required,
  errors,
  readonly,
  defaultValue,
  getValues,
  position = dropdownPosition.Bottom,
}) => {
  const { t } = useTranslation();
  const [isVisible, setVisibility] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);

  const searchInput = useRef(null);
  const content = useRef(null);

  useClick(content, isVisible, setVisibility);

  const onSearch = useCallback(() => {
    let text = searchInput.current?.value;
    setVisibleOptions(
      text
        ? options.filter((item) => item.text.search(new RegExp(text, "i")) >= 0)
        : options
    );
  }, [options]);

  const onSelectChange = useCallback(
    (option) => {
      setSelectedOption(option);

      setValue(name, option?.value, {
        shouldTouch: false,
        shouldValidate: true,
      });
      onChange?.();
      setVisibility(false);
    },
    [name, onChange, setValue]
  );

  const reset = useCallback(() => {
    let value = getValues?.(name) || defaultValue;

    if (value !== null && value !== undefined && options?.length > 0) {
      let index = options.findIndex((option) => option.value === `${value}`);

      onSelectChange(options[index]);
    } else setSelectedOption(undefined);
  }, [defaultValue, getValues, name, onSelectChange, options]);

  useEffect(() => {
    onSearch(options);
  }, [onSearch, options]);

  useEffect(() => {
    if (isVisible) {
      searchInput.current?.focus();
      onSearch();
    }
  }, [isVisible, onSearch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Quando name e type forem undefined quer dizer que o campo foi resetado
      if (name === undefined && type === undefined) {
        reset();
      }
    });
    return () => subscription.unsubscribe();
  }, [name, options, reset, watch]);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <div>
      <label className="text-[#8A92A6] text-[12px]">{label}</label>
      <input
        type="hidden"
        {...register?.(name, {
          required: required && `${t('components.dropdown.required')}`,
        })}
      />
      <div className="relative" ref={content}>
        <button
          type="button"
          onClick={() => setVisibility(true)}
          className={`${
            readonly
              ? "border-[#ccc] bg-[#f5f5f5]"
              : errors?.[name]
              ? "border-[#AF0505]"
              : "border-[#187733]"
          } relative flex justify-between items-center pl-3 ${py} ${width} font-semibold text-white  border  rounded-md  focus:outline-none focus:shadow-outline w-full`}
        >
          <div className="text-[#8A92A6] font-normal text-[12px]">
            {selectedOption ? selectedOption.text : defaultText}
          </div>
          <svg
            className="ml-2 h-6 w-6 text-[#BBB]"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" />
          </svg>
        </button>
        {isVisible && !readonly && (
          <div
            className={`${
              position === dropdownPosition.Top
                ? "bottom-full flex-col-reverse"
                : "top-full flex-col"
            } absolute flex left-0 w-full text-left font-normal z-10`}
          >
            <div className="text-black my-2 shadow-lg">
              <Input inputRef={searchInput} onChange={() => onSearch()} />
            </div>

            <div
              className={`max-h-[200px] rounded-md bg-white ring-1 ring-black ring-opacity-5 shadow-lg overflow-y-auto`}
            >
              <ul className="text-black cursor-pointer">
                {visibleOptions.length === 0 && (
                  <li className="text-gray-700 text-sm px-4 py-3 bg-[#ece6e6]">
                    Nenhuma opção encontrada
                  </li>
                )}

                {visibleOptions?.map((item, index) => (
                  <li
                    key={index}
                    className={`${
                      selectedOption?.value === item.value
                        ? "bg-[#187733] text-white"
                        : "text-gray-700 hover:bg-slate-100"
                    } block px-4 py-3 text-sm border-b `}
                    onClick={() => {
                      onSelectChange(item);
                    }}
                  >
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {errors?.[name] && (
        <span className="text-[#AF0505] text-[12px]">
          {errors?.[name].message}
        </span>
      )}
    </div>
  );
};

export default DropdownSingle;
