import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUser, selectUserToken, userLoggedOut } from "../features/user";
import Swal from "sweetalert2";
import { isUserExpired } from "../utility/util";

const useRequireLogin = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState(undefined);

  const logout = useCallback(() => {
    dispatch(userLoggedOut);
    navigate("/Conta/Login");
  }, [dispatch, navigate]);

  const logoutWithMessage = useCallback(() => {
    Swal.fire({
      title: "Erro!",
      text: "Login expirado",
      icon: "error",
    });

    logout();
  }, [logout]);

  useEffect(() => {
    let currentUrl = window.location.href;

    if (!user.isLogged || isUserExpired(token))
      setRedirectPath(`/Conta/Login?returnUrl=${currentUrl}`);

    if (user) setIsLoading(false);
  }, [location.pathname, token, user]);

  useEffect(() => {
    if (location.pathname.includes("Conta")) return;

    const expireInterval = setInterval(() => {
      if (isUserExpired(token)) {
        logoutWithMessage();
      }
    }, 1000);

    const logoutInterval = setInterval(() => {
      try {
        let token = JSON.parse(localStorage.state)?.user?.token;

        // Se o usuário realizou o logout, o localStorage vai retornar um objeto com token vazio
        if (!token) logout();
      } catch {
        // Se não houver nada no localStorage o state vai retornar '' e dar erro no JSON.parse
        logout();
      }
    }, 5000);

    return () => {
      clearInterval(expireInterval);
      clearInterval(logoutInterval);
    };
  }, [logoutWithMessage, location.pathname, logout, token]);

  return {
    isLoading,
    redirectPath,
  };
};

export default useRequireLogin;
