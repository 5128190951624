import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { selectUserToken } from "../../features/user";
import accountApi from "../../utility/api/accountApi";

import Input from "./../../components/Input";
import LoginSlider from "./../../components/LoginSlider";
import { Validator } from "./../../utility/validations";
import { useTranslation } from "react-i18next";

const RequisicaoRecuperacaoSenha = () => {
  const [showAlert, setShowAlert] = useState(true);
  const token = useSelector(selectUserToken);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (showAlert) {
      Swal.fire({
        title: `${t('requestRecoverPassword.notifications.information.title')}`,
        text: `${t('requestRecoverPassword.notifications.information.body')}`,
        icon: "info",
      });
      setShowAlert(false);
    }
  }, [showAlert]);

  const onSubmit = async ({ email }) => {
    var result = await accountApi.requisicaoRecuperacaoSenha({
      hostUrl: window.location.origin,
      email
    },
      token
    );
    if (result.hasError) {
      Swal.fire({
        title: "Ops!",
        text: result.error.response.data.notifications,
        icon: "info",
      });
      return;
    }
    if (result.data.length === 0) {
      Swal.fire({
        title: t('recoverPassword.success'),
        text: "Verifique sua caixa de email",
        icon: "success",
      });
      return;
    }
    if (result.data.length !== 0) {
      Swal.fire({
        title: t('recoverPassword.success'),
        text: result.data.message,
        icon: "success",
      });
      return;
    }

    // Swal.fire({
    //   title: "Erro!",
    //   text: result.data,
    //   icon: "error",
    // });
  };

  return (
    <div className="w-full h-screen m-f1:flex m-f1:flex-col d-f1:flex-row overflow-y-scroll d-f1:overflow-hidden">
      <div className="grow min-w-[50%] m-f1:h-[100%] m-f1:flex m-f1:justify-center d:h-screen ">
        <div className="w-full py-10 flex flex-col justify-center items-center">
          <div className="background-logo m-f1:w-[50%] m-f1:h-[30px] m-f2:w-[60%] m-f2:h-[42px] m-f1:flex m-f1:justify-center d-f1:hidden">
            <div className="m-f1:text-[#1D284A] m-f1:font-semibold m-f1:text-[20px] m-f2:text-[30px] m-f1:mt-10 m-f2:mt-20">
              Acessar
            </div>
          </div>
          <div className="m-f1:hidden d-f1:flex d-f1:w-full d-f1:items-center d-f1:px-[150px] d-f1:flex-col">
            <div className="background-logo w-full h-[62px] mb-12"></div>
            <div className="flex justify-center items-center">
              <div className="text-[#1D284A] font-semibold text-[20px] pr-4">
                {t('requestRecoverPassword.title')}
              </div>
            </div>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col m-f1:mt-2 d-f1:mt-6 m-f2:mt-4 m-f1:px-6 m-f3:px-12 l:px-72 d-f1:px-36 d-f4:px-64 d-f5:px-[400px] t-f1:px-32">
              <Input
                label={t('login.loginInputs.loginTitle')}
                name="email"
                type="text"
                register={register}
                validate={(text) =>
                  new Validator.Builder().required().build().validate(text)
                }
                placeholder={t('login.loginInputs.loginPlaceholder')}
                errors={errors}
              />
            </div>
            <div className="w-full flex justify-center items-center m-f2:mt-10">
              <button className="bg-[#1D284A] hover:bg-[#1E3784] m-f1:py-2 m-f2:py-3 m-f1:px-8 m-f2:px-14 text-[#FFF] font-semibold rounded-md text-[12px]">
                {t('requestRecoverPassword.buttonSend')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <LoginSlider />
    </div>
  );
};

export default RequisicaoRecuperacaoSenha;
