import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import estadoApi from "../../utility/api/estadoApi";
import useQuery from "../../utility/useQuery";
import CadastroEstados from "./CadastroEstados";
import EdicaoEstados from "./EdicaoEstados";
import { checarPermissao, NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const ConsultaEstados = () => {
  let isAllowed = useAthorize(
    permissoes.localizacaoConsulta,
    permissoes.localizacaoFull
  );

  const token = useSelector(selectUserToken);
  let isEdicaoAllowed = checarPermissao(token, permissoes.localizacaoFull);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/estado/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() =>{
    if (!isCadastroVisible) navigate('/estado/listagem');
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: "Estado",
      placeholder: "Pesquise pelo estado",
      name: "nome",
    },
    {
      label: "País",
      placeholder: "Pesquise pelo país",
      name: "pais",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "50%",
    },
    {
      label: "País",
      prop: "pais",
      width: "50%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: estadoApi.listarEstados,
      },
      editarLink: (item) =>
        `/estado/listagem?page=${pagina}&estadoId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroEstados
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoEstados
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Consulta
      title="Estados"
      adicionarButton={isEdicaoAllowed && adicionarButton}
      showOptions={isEdicaoAllowed}
      colunas={colunas}
      filtros={filtros}
      consulta={consulta}
    />
  );
};

export default ConsultaEstados;
