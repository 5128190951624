import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Avatar from "../../../../assets/imagens/png/Avatar-Padrao.png";
import SairImg from "../../../../assets/imagens/png/Logout.png";
import Dropdown from "./Dropdown";
import { useTranslation } from "react-i18next";
import accountApi from "../../../../utility/api/accountApi";
import { selectUser, selectUserToken, userLoggedIn } from "../../../../features/user";

const UserInfo = () => {
  const user = useSelector(selectUser);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);

  let userImagem = Avatar;

  const lngs = {
    pt: { nativeName: 'Português', id: 'portugues', nameChange: 'pt-BR' },
    en: { nativeName: 'Inglês', id: 'ingles', nameChange: 'en-US' },
  };

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng

  const loadState = async () => {
    const { data } = await accountApi.buscarTokenAtualizado({}, token);
    dispatch(userLoggedIn(data));
    window.location.reload();
  };

  return (
    <div className="flex">
      {Object.keys(lngs).map((lng) => (
        <button key={lng} id={lngs[lng].id} className={`w-[30px] h-[30px] rounded-[50%] border-2 my-3 mr-2 ${getLanguage() === lngs[lng].nameChange ? 'border-[#005BBF]' : 'border-white'} cursor-pointer m-f1:ml-10 d-f1:ml-0`} onClick={() => {
          i18n.changeLanguage(lngs[lng].nameChange, () => {
            loadState();
          });
        }}>
        </button>
      ))}
      <Dropdown userImagem={userImagem} user={user} />
      <div className="my-2 border-r-2 mx-2 m-f1:mx-0 t-f1:mx-2"></div>
      <div className="flex w-10 mx-2 items-center m-f1:mx-1">
        <NavLink
          to="/Conta/Logout"
          className="w-full h-full flex items-center justify-center"
        >
          <img alt="Sair" src={SairImg} />
        </NavLink>
      </div>
    </div>
  );
};

export default UserInfo;
