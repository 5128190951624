import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Usuario } from "./Cadastro";
import usuarioApi from "../../utility/api/usuarioApi";
import { selectUserToken } from "../../features/user";
import useAccessProfile from "../../hooks/useAccessProfile";
import useRequest, { Status } from "../../hooks/useRequest";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { NotAllowed } from "../NotAllowed";
import { useTranslation } from "react-i18next";

export const CadastroUsuario = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: true,
    },
  });
  const { t } = useTranslation();
  const { isManager } = useAccessProfile();

  const token = useSelector(selectUserToken);
  const [toggletEnabled, setToggleEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState(undefined);
  const [showUploadError, setShowUploadError] = useState(true);
  const navigate = useNavigate();

  const gerarSenha = useCallback(async () => {
    let response = await usuarioApi.buscarSenhaAleatoria(token);
    if (!response.hasError) {
      reset({
        ...getValues(),
        senha: response.data,
      });
      setPassword(response.data);
    }
  }, [getValues, reset, token]);

  const salvarUsuarioRequest = useRequest((data) =>
    usuarioApi.cadastrarUsuario(data, token)
  );

  useEffect(() => {
    gerarSenha();
  }, [gerarSenha]);

  useEffect(() => {
    setIsLoading(salvarUsuarioRequest.status === Status.Loading);

    switch (salvarUsuarioRequest.status) {
      case Status.Failed:
        sendDelayedMessage({
          title: `${t('components.attention')}`,
          text: salvarUsuarioRequest.data || "Algo deu errado",
          icon: "info",
        });
        break;
      case Status.Success:
        sendDelayedMessage({
          title: `${t('addOrUpdateUser.modalSucess.title')}`,
          text: `${t('addOrUpdateUser.modalSucess.text')}`,
          icon: "success",
        });
        navigate("/usuario/listagem");
        break;
    }
  }, [salvarUsuarioRequest.status, t]);

  const cancelForm = () => {
    Swal.fire({
      text: `${t('components.msgConfirmCancel')}`,
      showDenyButton: true,
      confirmButtonText: `${t('modalUser.yes')}`,
      denyButtonText: `${t('modalUser.no')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        resetForm();

        Swal.fire({
          text: `${t('components.msgCancel')}`,
          icon: "success",
        });
      }
    });
  };

  const resetForm = () => {
    reset();
    setToggleEnabled(true);
    gerarSenha();
  };

  const sendDelayedMessage = (message, isLoading) => {
    setTimeout(
      () => {
        Swal.fire(message);
        setIsLoading(false);
      },
      isLoading ? 1000 : 0
    );
  };

  const onSubmit = async (data) => {
    salvarUsuarioRequest.execute({
      nome: data.nome,
      login: data.login,
      senha: data.senha,
      email: data.email,
      divisionId: data.divisaoId,
      employeeId: data.employeeId,
      departmentIds: data.departamentos,
      divisionProfiles: (data.divisionProfiles || []).map((d) => ({
        divisionId: d.divisionId,
        profileIds: d.profiles.map((p) => p.id),
      })),
    });
  };

  if (!isManager) return <NotAllowed />;

  return (
    <>
      <Loading isVisible={isLoading} />
      <Usuario
        title={t('addOrUpdateUser.tabs.user.title2')}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        toggletEnabled={toggletEnabled}
        setToggleEnabled={setToggleEnabled}
        toggleIsVisible={false}
        isLoading={isLoading}
        resetForm={cancelForm}
        password={password}
        showUploadError={showUploadError}
        setShowUploadError={setShowUploadError}
        hasStatus={false}
      />
    </>
  );
};
