import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Input from "../../components/Input";
import { selectUser, selectUserToken, userLoggedIn } from "../../features/user";
import accountApi from "../../utility/api/accountApi";
import LoginSlider from "./../../components/LoginSlider";
import { Validator } from "../../utility/validations";
import useQuery from "../../utility/useQuery";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const lngs = {
  pt: { nativeName: 'Português', id: 'portugues', nameChange: 'pt-BR' },
  en: { nativeName: 'Inglês', id: 'ingles', nameChange: 'en-US' },
};

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const token = useSelector(selectUserToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  let query = useQuery();
  let returnUrl = query.get("returnUrl");
  let loginUrl = query.get("loginUrl");

  const externalLogin = useCallback(
    async (tokenModulo) => {
      if (!tokenModulo) {
        let result = await accountApi.buscarTokenModulo(
          {
            url: loginUrl,
            loginUrl: user.lembrar,
            moduloUrl: loginUrl,
          },
          token
        );

        if (result.hasError) {
          Swal.fire({
            title: "Algo deu errado!",
            icon: "error",
          });
        }

        tokenModulo = result.data;
      }

      tokenModulo = tokenModulo.replace("Bearer", "").trim();

      var url = new URL(loginUrl);
      url.searchParams.append("token", tokenModulo);
      window.location.href = url.href;
    },
    [loginUrl, token, user.lembrar]
  );

  useEffect(() => {
    if (loginUrl && token) {
      externalLogin();
    } else return;
  }, [externalLogin, loginUrl, token]);

  useEffect(() => {
    if (loginUrl) return;

    if (loginUrl) {
      Swal.fire({
        title: "Atenção!",
        text: "É necessário realizar o login para acessar esse recurso",
        icon: "info",
      });
    } else if (returnUrl) {
      // TODO verificar necessidade
      // Swal.fire({
      //   title: "Atenção!",
      //   text: "Acesso expirado",
      //   icon: "info",
      // });
    }

  }, [loginUrl, returnUrl]);

  const onSubmit = async ({ login, senha }) => {

    const { data } = await accountApi.login({
      login,
      senha
    });
    if (!data.isValid) {
      Swal.fire({
        title: t('error'),
        text: data.message,
        icon: "error",
      });
      navigate("/");
      return;
    }

    dispatch(userLoggedIn(data));

    if (loginUrl) {
      Swal.fire({
        title: "Módulo não cadastrado!",
        text: "Tente acessar o Módulo novamente",
        icon: "error",
      });
      navigate("/");
    } else if (returnUrl) window.location.href = returnUrl;
    else navigate("/");
  };

  if (loginUrl && token) return <></>;

  const url = `https://sso-portal.valgroupco.com/?url=${window.location.href}`;

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng


  return (
    <div className="w-full h-screen m-f1:flex m-f1:flex-col d-f1:flex-row overflow-y-scroll d-f1:overflow-hidden">
      <div className="grow min-w-[50%] m-f1:h-[100%] m-f1:flex m-f1:justify-center d:h-screen ">
        <div className="w-full py-10 flex flex-col justify-center items-center">
          <div className="background-logo m-f1:w-[50%] m-f1:h-[30px] m-f2:w-[60%] m-f2:h-[42px] m-f1:flex m-f1:justify-center d-f1:hidden">
            {/* <div className="m-f1:text-[#1D284A] m-f1:font-semibold m-f1:text-[20px] m-f2:text-[30px] m-f1:mt-10 m-f2:mt-20">
              Acessar
            </div> */}
          </div>
          <div className="w-full h-[50px] flex justify-center items-center mt-10 d-f1:hidden">
            <div className="text-[#1D284A] font-semibold text-[20px]">{t('changeLang')}</div>
            {Object.keys(lngs).map((lng) => (
              <button key={lng} id={lngs[lng].id} className={`w-[30px] h-[30px] rounded-[50%] border-2 m-4  ${getLanguage() === lngs[lng].nameChange ? 'border-[#005BBF]' : 'border-white'} cursor-pointer`}
                onClick={() => {
                  i18n.changeLanguage(lngs[lng].nameChange, () => {
                    window.location.reload();
                  });
                }}>
              </button>
            ))}
          </div>
          {/* Só aparece no desktop */}
          <div className="m-f1:hidden d-f1:flex d-f1:w-full d-f1:items-center d-f1:px-[150px] d-f1:flex-col">
            <div className="background-logo w-full h-[62px] mb-8"></div>
            <div className="flex justify-center items-center" id="idiomas">
              <div className="text-[#1D284A] font-semibold text-[22px]">
                {t('changeLang')}
              </div>
              {Object.keys(lngs).map((lng) => (
                <button key={lng} id={lngs[lng].id} className={`w-[30px] h-[30px] rounded-[50%] border-2 m-4 ${getLanguage() === lngs[lng].nameChange ? 'border-[#005BBF]' : 'border-white'} cursor-pointer `}
                  onClick={() => {
                    i18n.changeLanguage(lngs[lng].nameChange, () => {
                      window.location.reload();
                    });
                  }}>
                </button>
              ))}
            </div>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col m-f1:mt-2 m-f2:mt-8 m-f4:mt-12 m-f1:px-6 m-f3:px-12 l:px-72 d-f1:px-36 d-f4:px-64 d-f5:px-[400px] t-f1:px-32">
              <Input
                label={t('login.loginInputs.loginTitle')}
                name="login"
                type="text"
                placeholder={t('login.loginInputs.loginPlaceholder')}
                register={register}
                validate={(text) =>
                  new Validator.Builder().required().build().validate(text)
                }
                errors={errors}
              />
            </div>
            <div className="w-full flex flex-col m-f1:mt-2 d-f1:mt-6 m-f2:mt-4 m-f1:px-6 m-f3:px-12 l:px-72 d-f1:px-36 d-f4:px-64 d-f5:px-[400px] t-f1:px-32">
              <Input
                label={t('login.loginInputs.passwordTitle')}
                name="senha"
                type="password"
                placeholder={t('login.loginInputs.passwordPlaceholder')}
                register={register}
                validate={(text) =>
                  new Validator.Builder().required().build().validate(text)
                }
                errors={errors}
              />
            </div>
            <div className="w-full flex justify-between m-f1:px-6 m-f1:py-2 d-f1:px-36 m-f3:px-12 l:px-72 d-f4:px-64 t-f1:px-32">
              <div className="flex justify-center items-center">
                {/* <label className="text-[#8A92A6] text-[12px] pl-1 cursor-pointer flex items-center">
                  <input
                    {...register("lembrar")}
                    type="checkbox"
                    className="cursor cursor-pointer mr-1"
                  />
                  Lembrar
                </label> */}
              </div>
              <div className="text-[#8A92A6] text-[12px] underline cursor-pointer">
                <Link to="/Conta/RequisicaoRecuperacaoSenha" target="_blank">
                  {t('login.loginInputs.recoverPassword')}
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center m-f2:mt-4">
              <button className="bg-[#1D284A] hover:bg-[#1E3784] m-f1:py-2 m-f2:py-3 m-f1:px-8 m-f2:px-14 text-[#FFF] rounded-md text-[12px]">
                {t('buttonLogin')}
              </button>
              <div>{t('or')}</div>
              <a className="bg-[#EB3C00] hover:bg-[#eb3b00e0] m-f1:py-2 m-f2:py-3 m-f1:px-8 m-f2:px-4 text-[#FFF] rounded-md text-[12px]" href={url}>
                {t('buttonLoginWith365')}
              </a>
            </div>
          </form>
        </div>
      </div>
      <LoginSlider />
    </div >
  );
};

export default Login;
