import { combineReducers } from "redux";

import userReducer from "./user/userSlice";
import filtersReducer from "./filters/filtersSlice";

const rootReducer = combineReducers({
  user: userReducer,
  filters: filtersReducer,
});

export default rootReducer;
