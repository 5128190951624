import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from "../../components/Consulta/Pagination";
import Card from "../../components/Consulta/Card";
import InformacaoNaoEncontrada from "../../components/Consulta/InformacaoNaoEncontrada";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import serviceApi from "../../utility/api/serviceApi";
import FilterChamados from "./FilterChamados";
import useQuery from "../../utility/useQuery";
import Swal from "sweetalert2";

export default function ListChamados ({
  colunas,
  adicionarLink,
  adicionarButton,
  showOptions,
  hasLinkServiceAide,
  style,
  customTableName
}) {
  const { t } = useTranslation();
  const token = useSelector(selectUserToken);

  let query = useQuery();
  let pagina = parseInt(query.get("page") ?? 0);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(pagina);
  const [qtdTotal, setQtdTotal] = useState(5);
  const [qtdItens, setQtdItens] = useState(5);
  const [showFilter, setShowfilter] = useState(false)

  const [isFiltroVisible, setIsFiltroVisible] = useState(false);
  const [cardOptionsIndex, setCardOptionsIndex] = useState(-1);
  const [cards, setCards] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState(undefined);

  const events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: serviceApi.listarChamados,
      },
      editarLink: (item) => `${item.linkChamado}`,
    };
  }, [t]);

  useEffect(() => {
    let queryNome = searchText.length > 0 ? searchText : null;
    let queryStatus = selectedOption != null ? selectedOption.value : null;
    
    if(isLoading == true){
      if(window.localStorage.getItem('cacheQueryChamados') != null) {
        let cache = JSON.parse(window.localStorage.getItem('cacheQueryChamados'));

        queryNome = cache.queryNome != null ? cache.queryNome : '';
        setSearchText(queryNome)

        queryStatus = cache.queryStatus != null ? cache.queryStatus : '';
        setSelectedOption(queryStatus)
      }
    }

    let data = {
      id: "Chamados TI Portal ServiceAide",
      pagina: page,
      quantidadeItems: qtdItens,
      queryNome: queryNome,
      queryStatus: queryStatus
    }

    getChamados(data)
  }, [page])

  useEffect(() => {
    setPage(pagina)
  }, [pagina])

  async function getChamados(data){
    try {
      let response = await serviceApi.listarChamados(data, token)

      if(response.data){
        if(response.data.statusRequest == "Success"){
          if(response.data.cards != null) setCards(response.data.cards)
          setQtdTotal(response.data.quantidadeTotal)
          setQtdItens(response.data.quantidadeItems)
          setStatusList(response.data.statusList)
        } else {
          Swal.fire({
            title: `${t('serviceAide.title')}`,
            text: `${t('serviceAide.error')}`,
            icon: "error",
          });
        }
        
        setIsLoading(false)
      }
    } catch(error) { }
    
  }

  const toggleFiltro = (isVisible) => {
    setIsFiltroVisible(isVisible);
    setShowfilter(isVisible);
  };

  if (isLoading) return <Loading isVisible={isLoading} />;

  return (
    <div className="w-full min-h-screen flex justify-center">
      <div className="w-[92%] h-52 mt-28" style={style}>
        {/* Versão Tablet e Desktop */}
        <div className="flex justify-between m-f1:hidden t-f1:flex">
          <div className="flex items-center">
            <div className="text-[28px] font-semibold mr-4 text-[#1B2646]">
              {`${t('serviceAide.title')}`}
            </div>
            <div>
              {adicionarButton ??
                (adicionarLink && (
                  <Link
                    to={adicionarLink}
                    className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                  >
                    {t('pageUser.buttons.add')}
                  </Link>
                ))}
            </div>
          </div>
          <div>
              <button
                className="bg-[#005BBF] text-[#FFF] mt-1 py-1 px-10 rounded-md hover:bg-[#1E3784]"
                onClick={() => toggleFiltro(!isFiltroVisible)}
              >
                {t('pageUser.buttons.filter.buttonTitle')}
              </button>
            </div>
        </div>
        {/* Versão Mobile */}
        <div className="text-[28px] font-semibold mr-4 text-[#1B2646] t-f1:hidden">
            {`${t('serviceAide.title')}`}
        </div>
        <div className="flex justify-between mt-3 t-f1:hidden">
          <div className="mt-1">
            {adicionarButton ??
              (adicionarLink && (
                <Link
                  to={adicionarLink}
                  className="bg-[#005BBF] text-[#FFF] py-1 px-5 my-0 rounded-md hover:bg-[#1E3784]"
                >
                  {t('pageUser.buttons.add')}
                </Link>
              ))}
          </div>

        </div>

        <FilterChamados 
          getChamados={getChamados} 
          showForm={showFilter}  
          statusList={statusList}
          searchText={searchText}
          setSearchText={setSearchText}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        {cards != null && typeof cards != 'undefined' &&
          <>
            {cards.length > 0 && (
              <h1 className="text-[#1B2646] font-medium mt-10 text-[20px]">
                {customTableName != null ?
                  <>{customTableName}</>
                    :
                  <>{t('pageUser.list.title')}</>
                }
              </h1>
            )}
          </>
        }
        
        <div className="min-h-[440px]">
          {cards != null && typeof cards != 'undefined' &&
            <>{cards.length === 0 && <InformacaoNaoEncontrada />}</>
          }

          {cards.map((item, index) => (
            <Card
              key={index}
              item={item}
              colunas={colunas}
              index={index}
              //search={events.onSearch}
              //reset={reset}
              cardOptionsIndex={cardOptionsIndex}
              setCardOptionsIndex={setCardOptionsIndex}
              events={events}
              showOptions={showOptions}
              hasLinkServiceAide={hasLinkServiceAide}
            />
          ))}
        </div>
        
        {cards != null &&  
          <>
              {cards.length > 0 &&
                qtdTotal > qtdItens && (
                  <div className="w-full h-[100px] flex items-center justify-end">
                    <Pagination
                      currentPage={pagina}
                      recordsTotal={qtdTotal}
                      recordsFiltered={qtdItens}
                      setPage={setPage}
                    />
                  </div>
                )}
          </>
        }
       
      </div>
    </div>
  );
};

