const SubMenu = ({ isVisibleSubMenuModulo, text, children, icon }) => {
  return (
    <div
      className={`absolute bg-[#f1ecec] z-0 h-screen left-0 w-56 -translate-x-full ${
        isVisibleSubMenuModulo && "left-14 translate-x-full "
      } duration-500 mt-14 pt-2`}
    >
      <div className="w-full h-full">
        <div className="w-full flex justify-center">
          <div className="w-[90%] h-full flex items-end border-b border-[#1D284A]">
            <div className="py-2 font-semibold text-[#1D284A] flex">
              <img alt="Módulos" src={icon} className="pr-3" />
              {text}
            </div>
          </div>
        </div>
        <div className="h-4/5 overflow-auto grid grid-rows-[repeat(auto-fill,_4.5rem)] gap-2">
          {children}
        </div>
      </div>
    </div>
  );
};
export default SubMenu;
