import { useCallback, useEffect, useState } from "react";
import Dropdown from "../../Dropdown";
import { FiltroTipo } from "../typing";

const Filtro = ({ filtro, register, search, watch, setValue, getValues }) => {
  const [options, setOptions] = useState([]);

  const fetch = useCallback(async () => {
    var result = await filtro.fetch();

    if (result.hasError) return;

    setOptions(result.data);
  }, [filtro]);

  useEffect(() => {
    if (filtro.fetch) fetch();
  }, [fetch, filtro.fetch]);

  if (!filtro.tipo || filtro.tipo === FiltroTipo.Input) {
    return (
      <div className="flex m-f1:w-full t-f1:w-[30%] m-f1:mr-0 t-f1:mr-[5%] t-f1:ml-[4%] t-f1:pt-0 m-f1:pt-[3%]">
        <div className="w-full flex flex-col h-16">
          <div className="text-[14px] text-[#8A92A6]">{filtro.label}</div>
          <div className="text-[14px]">
            <input
              className="w-full pl-3 border border-[#686868] rounded-md py-3 text-black"
              placeholder={filtro.placeholder}
              {...register(filtro.name)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (filtro.tipo === FiltroTipo.Select) {
    return (
      <div className="w-[30%] mr-[5%] flex">
        <div className="w-full flex flex-col h-16">
          <div className="text-[14px] text-[#8A92A6]">{filtro.label}</div>
          <Dropdown
            defaultText={filtro.placeholder}
            name={filtro.name}
            register={register}
            options={options}
            setValue={setValue}
            fetch={filtro.fetch}
            watch={watch}
            getValues={getValues}
          />
        </div>
      </div>
    );
  }
};

export default Filtro;
