import api from "./index";

const divisaoApi = {
  listarDivisoes: async (data, token) => {
    return await api.get(`/Divisao/Listar`, data, token);
  },
  cadastrarDivisao: async (data, token) => {
    return await api.post(`/Divisao/Cadastrar`, data, token);
  },
  atualizarDivisao: async (data, token) => {
    return await api.put(`/Divisao/Editar`, data, token);
  },
  inativarDivisao: async (id, token) => {
    return await api.put(`/Divisao/Inativar`, { divisaoId: id }, token);
  },
  ativarDivisao: async (id, token) => {
    return await api.put(`/Divisao/Ativar`, { divisaoId: id }, token);
  },
  excluirDivisao: async (id, token) => {
    return await api.delete(`/Divisao/Excluir`, { divisaoId: id }, token);
  },
  buscarDivisao: async (data, token) => {
    return await api.get(`/Divisao/Buscar`, data, token);
  },
  listarPaises: async (data, token) => {
    return await api.get(`/Divisao/ListarPaises`, data, token);
  },
  listarEstados: async (data, token) => {
    return await api.get(`/Divisao/ListarEstados`, data, token);
  },
  listarCidades: async (data, token) => {
    return await api.get(`/Divisao/ListarCidades`, data, token);
  },
  listarDropdown: async (token) => {
    return await api.get(`/api/Division/GetAll`, null, token);
  },
};

export default divisaoApi;
