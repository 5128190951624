import { useId, useState } from "react";

import OlhoAberto from "./../../assets/svg/olho-aberto.svg";
import OlhoFechado from "./../../assets/svg/olho-fechado.svg";

const InputMultiline = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  register,
  validate,
  errors,
  readonly,
  onChange,
  required,
  icon,
  button,
  inputRef,
  multiline,
}) => {
  const id = useId();
  const [isVisible, setIsVisible] = useState(false);

  const onTextChange = () => {
    var textarea = document.getElementById(id);
    textarea.style.height = `1px`;
    var height = textarea.scrollHeight + 5;
    textarea.style.height = `calc(${height}px)`;
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id} className="text-[#8A92A6] text-[12px] pt-1">
          {label}
        </label>
      )}
      <div className="relative w-full">
        <textarea
          id={id}
          ref={inputRef}
          readOnly={readonly}
          onChange={() => {
            onTextChange();
            onChange?.();
          }}
          {...register?.(name, {
            validate: validate,
            value: value,
            onChange: () => {
              onTextChange();
              onChange?.();
            },
            required: required && "Este campo não pode ficar vazio",
          })}
          className={`w-full border border-[#686868] rounded-md px-2 m-f1:py-1 m-f2:py-2 d-f1:py-3 text-[12px] outline-none resize-none max-h-52
          ${icon && "pl-10"} 
          ${readonly && "bg-[#ece6e6] border-[#ece6e6]"}
          ${errors?.[name] && "!border-[#AF0505]"}`}
          type={isVisible ? "text" : type}
          placeholder={placeholder}
        ></textarea>
        {icon && (
          <img
            className="absolute top-1/2 left-2 -translate-y-1/2"
            alt="Icone de Nome"
            src={icon}
          />
        )}
        {button}
        {type === "password" && (
          <button
            type="button"
            onClick={() => !readonly && setIsVisible(!isVisible)}
            className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
          >
            <img
              alt="Exibir senha"
              title="Exibir senha"
              src={isVisible ? OlhoAberto : OlhoFechado}
            />
          </button>
        )}
      </div>
      <div className="flex justify-start">
        {errors?.[name] && (
          <span className="text-[#AF0505] text-[12px] my-[3px]">
            {errors?.[name].message}
          </span>
        )}
      </div>
    </div>
  );
};

export default InputMultiline;
