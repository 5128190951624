import api from "./index";

const usuarioApi = {
  listarUsuarios: async (data, token) => {
    return await api.get(`/UserSystem/List`, data, token);
  },
  cadastrarUsuario: async (data, token) => {
    return await api.post(`/UserSystem/Create`, data, token);
  },
  atualizarUsuario: async (data, token) => {
    return await api.put(`/UserSystem/Update`, data, token);
  },
  inativarUsuario: async (id, token) => {
    return await api.put(`/Usuarios/Inativar`, { userId: id }, token);
  },
  ativarUsuario: async (id, token) => {
    return await api.put(`/Usuarios/Ativar`, { userId: id }, token);
  },
  excluirUsuario: async (id, token) => {
    return await api.delete(`/UserSystem/Delete`, { userId: id }, token);
  },
  buscarUsuario: async (userId, token) => {
    return await api.get(`/UserSystem/Get/${userId}`, null, token);
  },
  buscarSenhaAleatoria: async (token) => {
    return await api.get(`/UserSystem/GetRandomPassword`, null, token);
  },
};

export default usuarioApi;
