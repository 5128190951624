import { useState } from "react";
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import {
  faChevronRight,
  faChevronDown,
  faSquareCheck,
  faSquareMinus,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
  faSquarePlus as faSquarePlusRegular,
  faSquareMinus as faSquareMinusRegular,
} from '@fortawesome/free-regular-svg-icons';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './react-checkbox-tree-overrides.css';
import { useEffect } from "react";

const PerfilAcessoCollapse = ({ 
  modulos, 
  modulosDoPerfil, 
  onCheck,
}) => {
  const { t } = useTranslation();
  const [nodes, setNodes] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState({
    checked: [],
    expanded: [],
  });

  useEffect(() => {
    if ((modulos && Array.isArray(modulos)) &&
      (modulosDoPerfil && Array.isArray(modulosDoPerfil))) {
      // NODES
      setNodes(modulos.map(m => ({
        value: m.id,
        label: m.name,
        children: m.functions.map(f => ({
          value: f.id,
          label: f.name,
          children: f.actions.map(a => ({
            value: `${f.id}:${a.id}`,
            label: a.name,
          })),
        })),
      })));

      // CHECKED NODES
      const actions = [];
      modulosDoPerfil.forEach(m => {
        m.functions.forEach(f => {
          f.actions.forEach(a => {
            actions.push(`${f.id}:${a.id}`);
          });
        });
      });

      setCheckedNodes({ checked: actions });
    }
  }, [modulos]);

  useEffect(() => {
    const updatedModules = [];

    modulos.forEach(m => {
      m.functions.forEach(f => {
        f.actions.forEach(a => {
          if (checkedNodes.checked.includes(`${f.id}:${a.id}`)) {
            let module = updatedModules.find(x => x.id === m.id);
            if (!module) {
              module = {
                id: m.id,
                functions: [],
              };
              updatedModules.push(module);
            }

            let func = module.functions.find(x => x.id === f.id);
            if (!func) {
              func = {
                id: f.id,
                actions: [],
              };
              module.functions.push(func);
            }

            let action = func.actions.find(x => x.id === a.id);
            if (!action) {
              action = {
                id: a.id,
              };
              func.actions.push(action);
            }
          }
        });
      });
    });

    if (onCheck) {
      onCheck(updatedModules);
    }
  }, [checkedNodes]);

  return (
    <CheckboxTree
      nodes={nodes}
      checked={checkedNodes.checked}
      expanded={checkedNodes.expanded}
      onCheck={checked => setCheckedNodes({ ...checkedNodes, checked })}
      onExpand={expanded => setCheckedNodes({ ...checkedNodes, expanded })}
      optimisticToggle={false}
      expandOnClick={true}
      onClick={() => { }}
      showExpandAll={true}
      icons={{
        check: <FontAwesomeIcon icon={faSquareCheck} color="#187733" />,
        uncheck: <FontAwesomeIcon icon={faSquare} color="#888" />,
        halfCheck: <FontAwesomeIcon icon={faSquareMinus} color="#187733" />,
        expandClose: <FontAwesomeIcon icon={faChevronRight} color="#888" />,
        expandOpen: <FontAwesomeIcon icon={faChevronDown} color="#888" />,
        expandAll: <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 15,
        }}>
          <FontAwesomeIcon
            size="lg"
            icon={faSquarePlusRegular}
            color="#555"
            style={{ marginRight: 8 }} />
          <p>{t('components.buttons.expand')}</p>
        </div>,
        collapseAll: <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <FontAwesomeIcon
            size="lg"
            icon={faSquareMinusRegular}
            color="#555"
            style={{ marginRight: 8 }} />
          <p>{t('components.buttons.close')}</p>
        </div>,
        parentClose: <EmptyNodeIcon />,
        parentOpen: <EmptyNodeIcon />,
        leaf: <EmptyNodeIcon />,
      }}
    />
  );

  // return (
  //   <>
  //     {modulos.map((modulo, index) => (
  //       <div className="mb-5" key={index}>
  //         <Modulo
  //           modulo={modulo}
  //           visibleModule={visibleModule}
  //           setVisibleModule={setVisibleModule}
  //         />
  //       </div>
  //     ))}
  //   </>
  // );
};

const EmptyNodeIcon = () => {
  return <div style={{ marginLeft: -24 }}></div>;
}

export default PerfilAcessoCollapse;
