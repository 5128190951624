const Index = ({children, className}) => {
    return(
        <section className={`overflow-y-auto overflow-x-hidden flex flex-col items-center place-items-center ${className}`}>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 px-4" style={{width: 100 + '%'}}>
                {children}
            </div>
        </section>
    );
}

export default Index;

