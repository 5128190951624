import { useState } from "react";

export const Status = {
  Idle: 'idle',
  Loading: 'loading',
  Success: 'success',
  Failed: 'failed'
};

export default function useRequest(requestFunction) {
  const [status, setStatus] = useState(Status.Idle);
  const [data, setData] = useState();

  const execute = async (data) => {
    setStatus(Status.Loading);
    setData(undefined);
    try {
      const result = await requestFunction(data);
      if (result.hasError) {
        if (result.error.response && result.error.response.data) {
          setData(result.error.response.data.notifications);
        }
        setStatus(Status.Failed);
        return;
      }
      setData(result.data);
      setStatus(Status.Success);
    } catch (exception) {
      setData(exception);
      setStatus(Status.Failed);
    }
  };

  return {
    status,
    data,
    execute,
  }
}