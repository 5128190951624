import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import CancelarButton from "../../components/CancelarButton";
import useQuery from "../../utility/useQuery";
import { selectUserToken } from "../../features/user";
import useAccessProfile from "../../hooks/useAccessProfile";
import { useEffect } from "react";
import { useState } from "react";
import useRequest, { Status } from "../../hooks/useRequest";
import Loading from "../../components/Loading";
import divisaoApi from "../../utility/api/divisaoApi";
import departamentoApi from "../../utility/api/departamentoApi";
import Dropdown from "../../components/Dropdown";
import { NotAllowed } from "../NotAllowed";
import Toggle from "../../components/Toggle";
import { useTranslation } from "react-i18next";

const EdicaoDepartamento = () => {
  const { t } = useTranslation();
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const {
    handleSubmit,
    watch,
    getValues,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [divisions, setDivisions] = useState();

  const departmentId = query.get("departmentId");

  const { isManager } = useAccessProfile();

  const divisionsRequest = useRequest(() => divisaoApi.listarDropdown(token));
  const departmentRequest = useRequest(() =>
    departamentoApi.buscarDepartamento(departmentId, token)
  );
  const editDepartmentRequest = useRequest((data) =>
    departamentoApi.atualizarDepartamento(data, token)
  );

  useEffect(() => {
    divisionsRequest.execute();
    departmentRequest.execute();
  }, []);

  useEffect(() => {
    if (divisionsRequest.data) {
      setDivisions(
        divisionsRequest.data.map((item) => ({
          value: item.id,
          text: item.name,
        }))
      );
    }

    if (departmentRequest.data) {
      const department = departmentRequest.data;
      reset({
        name: department.description,
        divisionId: department.divisionId,
        hasSCI: department.hasSCI,
      });
    }
  }, [divisionsRequest.data, departmentRequest.data]);

  useEffect(() => {
    setLoading(
      departmentRequest.status === Status.Loading ||
        editDepartmentRequest.status === Status.Loading
    );

    if (editDepartmentRequest.status === Status.Success) {
      resetForm();
      Swal.fire({
        title: `${t('addOrUpdateUser.modalSucess.title')}`,
        text: `${t('addOrUpdateUser.modalSucess.text')}`,
        icon: "success",
      });
      navigate("/departamento/listagem");
    }

    if (editDepartmentRequest.status === Status.Failed) {
      Swal.fire({
        title: `${t('components.attention')}`,
        text: editDepartmentRequest.data || "Algo deu errado",
        icon: "info",
      });
    }
  }, [
    departmentRequest.status,
    divisionsRequest.status,
    editDepartmentRequest.status,
  ]);

  const onSubmit = (data) => {
    editDepartmentRequest.execute({
      id: departmentId,
      description: data.name,
      divisionId: data.divisionId,
      hasSCI: data.hasSCI,
    });
  };

  const resetForm = useCallback(() => {
    Swal.fire({
      text: `${t('components.msgConfirmCanceEdit')}`,
      showDenyButton: true,
      confirmButtonText: `${t('modalUser.yes')}`,
      denyButtonText: `${t('modalUser.no')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        reset();

        Swal.fire({
          text: `${t('components.msgCancel')}`,
          icon: "success",
        });
      }
    });
  }, [reset]);

  function withRequestStatus(request, value) {
    const isLoading =
      request.status === Status.Idle || request.status === Status.Loading;
    const hasError = request.status === Status.Failed;
    if (isLoading) {
      return `${t('components.loading')}`;
    }
    if (hasError) {
      return `${t('error')}`;
    }
    return value;
  }

  if (!isManager) return <NotAllowed />;

  return (
    <>
      {loading && <Loading isVisible={true} />}
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="w-full min-h-screen flex flex-col pb-[60px]">
          <div className="flex flex-col justify-center pt-[100px] mx-[15%]">
            <div className="mb-4 text-[24px] font-semibold text-[#1B2646]">
            {t('addOrUpdateDepartment.add.title2')}
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <label>{t('addOrUpdateDepartment.add.fields.division.label')}</label>
                <Dropdown
                  name="divisionId"
                  defaultText={withRequestStatus(
                    divisionsRequest,
                    `${t('addOrUpdateDepartment.add.fields.division.placeholder')}`
                  )}
                  options={divisions}
                  py="py-[8px]"
                  width={"w-full"}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  required
                  register={register}
                  errors={errors}
                />
              </div>
              <div>
                <label>{t('addOrUpdateDepartment.add.fields.description.label')}</label>
                <Input
                  name="name"
                  register={register}
                  errors={errors}
                  required={true}
                />
              </div>
              <div>
                <label>{t('addOrUpdateDepartment.add.fields.haveSCI')}</label>
                <Toggle
                  name="hasSCI"
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <div className="w-[70%] undefined bg-white rounded-md border border-[#ECECEC] py-4 flex items-center justify-between px-11">
              <CancelarButton
                voltar={() => navigate("/departamento/listagem")}
                watch={watch}
                getValues={getValues}
                reset={resetForm}
                wide
              />
              <button className="w-[181px] h-[44px] bg-[#187733] text-white rounded-[4px]">
                {t('components.buttons.save')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EdicaoDepartamento;
