import { Link } from "react-router-dom";
import bg from "../../assets/imagens/bg-default.jpg";
import logo from "../../assets/imagens/png/logo-branca.png";

const Services = ({ url, title, subtitle, onClick, to}) => {
    return (
        <Link className="relative group" onClick={onClick} to={to}>
          <div className="flex flex-col justify-center w-100 h-20 rounded-md border-2 bg-cover bg-no-repeat bg-center py-2 pl-3 text-white" style={{backgroundImage: `url('${bg}')`}}>
              <div className="flex items-center gap-2">
                <div>
                  <img className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full border-[1px] border-white" src={url == '' ? logo : url} />
                </div>
                <div className="max-w-[80%]">
                  <p className="text-[1rem] pl-[0.5rem]">{title}</p>
                  <p className="text-sm pl-[0.5rem] truncate">{subtitle}</p>
                </div>
              </div>
          </div>
          
          <div className="absolute top-20 hidden group-hover:flex group-hover:flex-col group-hover:items-center group-hover:z-50">
            <div className="w-3 h-3 -mb-2 rotate-45 bg-black"></div>
            <div className="relative rounded-md z-10 p-4 leading-none text-white whitespace-no-wrap bg-black shadow-lg flex flex-col">
              <span className="text-sm"><b>Título:</b> {title}</span>
              <span className="text-xs text-justify"><b>Descrição:</b> {subtitle}</span>
            </div>
          </div>
        </Link>
    );
}

export default Services;