import Filtro from "./Filtro";
import useLocallyStoredFilter from "../../../hooks/useLocallyStoredFilter";
import { useState } from "react";
import { useEffect } from "react";

const Filtros = ({
  id,
  configuration,
  register,
  handleSubmit,
  setValue,
  isFiltroVisible,
  toggleFiltro,
  search,
  watch,
  getValues,
}) => {
  const { updateFilter, filterEmpty } = useLocallyStoredFilter(
    id, (prop, filter) => setValue(prop, filter[prop]));

  useEffect(() => {
    if (!filterEmpty) toggleFiltro(true);
  }, [filterEmpty]);

  return (
    isFiltroVisible && (
      <form
        onChange={(_) => {
          updateFilter({
            id,
            ...getValues(),
          });
        }}
        onSubmit={handleSubmit(search)}
        className="w-full border bg-white text-[#FFF] mt-10 rounded-lg m-f1:flex-col t-f1:flex-row m-f1:px-[4%] t-f1:px-0 m-f1:pt-[4%] t-f1:pt-0 t-f1:h-[150px] m-f1:h-[270px]"
      >
        <button
          type="button"
          className="w-full py-2 px-5 text-[#187733] font-semibold cursor-pointer flex items-end justify-end"
          onClick={() => {
            toggleFiltro(false);
            updateFilter({ id });
          }}
        >
          x
        </button>
        {configuration.map((filtro, index) => (
          <Filtro
            key={index}
            filtro={filtro}
            register={register}
            search={search}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
          />
        ))}
      </form>
    )
  );
};

export default Filtros;
