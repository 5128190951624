import { useSelector } from "react-redux";
import { selectUser } from "../features/user";

const useAccessProfile = () => {
  const user = useSelector(selectUser);

  return {
    isManager: user.type === 0,
    modules: user.modules,
    token: user.token,
    accessToken: user.accessToken,
  };
};

export default useAccessProfile;
