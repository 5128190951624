import api from "./index";

const accountApi = {
  login: async (data) => {
    return await api.post(`/Account/Authenticate`, data);
  },
  loginAD: async (data) => {
    return await api.post(`/Account/AuthenticateAD`, data);
  },
  getCurrentUser: async () => {
    return await api.get(`/Account/GetCurrentUser`);
  },
  primeiroAcesso: async (senha, confirmarSenha, token) => {
    return await api.post(
      `/Account/AtualizarSenha`,
      { senha, confirmarSenha },
      token
    );
  },
  senhaExpirada: async (data, token) => {
    return await api.post(`/Account/AtualizarSenha`, data, token);
  },
  logout: async () => {
    return await api.get(`/Account/Logout`);
  },
  requisicaoRecuperacaoSenha: async (data, token) => {
    return await api.post(`/Account/RequisicaoRecuperacaoSenha`, data, token);
  },
  recuperarSenha: async (data, token) => {
    return await api.post(`/Account/RecuperacaoSenha`, data, token);
  },
  buscarTokenModulo: async (data, token) => {
    return await api.get(`/Account/BuscarTokenModulo`, data, token);
  },
  buscarTokenAtualizado: async (data, token) => {
    return await api.get(`/Account/RefreshToken`, data, token);
  },
};

export default accountApi;
