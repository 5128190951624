import "./style.css";

const WifiAnimation = () => {
  return (
    <div className="p-[25px] relative">
      <div className="wifi-symbol">
        <div className="wifi-circle first"></div>
        <div className="wifi-circle second"></div>
        <div className="wifi-circle third"></div>
        <div className="wifi-circle fourth"></div>
      </div>
    </div>
  );
};

export default WifiAnimation;
