import { useNavigate, Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardAction = ({
  index,
  item,
  search,
  reset,
  isAtivo,
  isCardOptionsVisible,
  setCardOptionsIndex,
  onAtivar,
  onInativar,
  onExcluir,
  showExcluir,
  editarLink,
  editarEvent,
  hasLinkServiceAide
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-end relative">
        {hasLinkServiceAide ? (
          <button onClick={() => setCardOptionsIndex(isCardOptionsVisible ? -1 : index)} type="button" className="acoes w-[40px] h-[40px] bg-[#F4F4F4] rounded-[50%] flex flex-col items-center cursor-pointer">
            <div className={`w-auto top-[100%] h-auto mt-2 absolute rounded-lg bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 overflow-y-auto ${isCardOptionsVisible ? "" : "hidden"}`}>
              <ul className="text-black cursor-pointer flex flex-col items-end">
                <a className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100" href={editarLink} target="_blank">{t('serviceAide.viewEdit')}</a>
              </ul>
            </div>
          </button>
        ) : (
              <button
                type="button"
                onClick={() => setCardOptionsIndex(isCardOptionsVisible ? -1 : index)}
                onBlur={() => setCardOptionsIndex(-1)}
                className="acoes w-[40px] h-[40px] bg-[#F4F4F4] rounded-[50%] flex flex-col items-center cursor-pointer"
              >
                <div
                  className={`w-auto top-[100%] h-auto mt-2 absolute rounded-lg bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 overflow-y-auto ${isCardOptionsVisible ? "" : "hidden"
                    }`}
                >
                  <ul className="text-black cursor-pointer flex flex-col items-end">
                    <li
                    className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100"
                    onClick={() => {
                      editarEvent?.();
                      editarLink && navigate(editarLink);
                      setCardOptionsIndex(-1);
                    }}
                   >
                     {t('pageUser.list.action.edit')}
                   </li>
                    {onInativar && onAtivar && (
                      <li
                        className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100"
                        onClick={() => {
                          setCardOptionsIndex(-1);
                          isAtivo
                            ? onInativar?.(item, search)
                            : onAtivar?.(item, search);
                        }}
                      >
                        {isAtivo ? `${t('addOrUpdateProfileAccess.column.action.inactivate.title')}` : `${t('addOrUpdateProfileAccess.column.action.activate')}`}
                      </li>
                    )}

                    {showExcluir && (
                      <li
                        className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] hover:bg-slate-100"
                        onClick={() => {
                          setCardOptionsIndex(-1);
                          onExcluir?.(item, search, reset);
                        }}
                      >
                        {t('pageUser.list.action.delete')}
                      </li>
                    )}
                  </ul>
                </div>
              </button>
        )}
      </div>
    </>
  );
};

export default CardAction;
