import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFilter, updateFilterAction } from "../features/filters";

export default function useLocallyStoredFilter(id, propMap) {
  const dispatch = useDispatch();
  const filter = useSelector((state) => selectFilter(state, id));
  const [filterEmpty, setFilterEmpty] = useState(true);

  useEffect(() => {
    if (filter) {
      const filterProps = Object.getOwnPropertyNames(filter);
      filterProps.forEach(prop => propMap(prop, filter));
      if (filterProps.length > 1) {
        setFilterEmpty(false);
      }
    }
  }, [filter]);

  function updateFilter(values) {
    dispatch(updateFilterAction(values));
  }

  return {
    updateFilter,
    filterEmpty,
  }
}