import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import { checarPermissao, NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";
import cidadeApi from "../../utility/api/cidadeApi";
import useQuery from "../../utility/useQuery";
import CadastroCidades from "./CadastroCidades";
import EdicaoCidades from "./EdicaoCidades";

const ConsultaCidades = () => {
  let isAllowed = useAthorize(
    permissoes.localizacaoConsulta,
    permissoes.localizacaoFull
  );

  const token = useSelector(selectUserToken);
  let isEdicaoAllowed = checarPermissao(token, permissoes.usuarioFull);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/cidade/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/cidade/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: "Cidade",
      placeholder: "Pesquise pela cidade",
      name: "nome",
    },
    {
      label: "Estado",
      placeholder: "Pesquise pelo estado",
      name: "estado",
    },
    {
      label: "País",
      placeholder: "Pesquise pelo país",
      name: "pais",
    },
  ];

  const colunas = [
    {
      label: "Cidade",
      prop: "nome",
      width: "30%",
    },
    {
      label: "Estado",
      prop: "estado",
      width: "30%",
    },
    {
      label: "País",
      prop: "pais",
      width: "30%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: cidadeApi.listarCidades,
      },
      editarLink: (item) =>
        `/cidade/listagem?page=${pagina}&cidadeId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroCidades
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoCidades
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Consulta
      title="Cidades"
      adicionarButton={isEdicaoAllowed && adicionarButton}
      colunas={colunas}
      filtros={filtros}
      consulta={consulta}
      showOptions={isEdicaoAllowed}
    />
  );
};

export default ConsultaCidades;
