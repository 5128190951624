import { ColunaTipo } from "../typing";
import Avatar from "../../../assets/imagens/png/Avatar-Padrao.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const CardColuna = ({
  configuration: { prop, tipo, link, width, label, customContent },
  item,
}) => {
  let innerContent = (
    <div className="text-left text-[14px] font-semibold w-full whitespace-nowrap overflow-hidden text-ellipsis pr-8">
      <Tippy content={item[prop]}>
        {customContent ? customContent(item) : <span>{item[prop]}</span>}
      </Tippy>
    </div>
  );

  if (tipo === ColunaTipo.Image) {
    innerContent = (
      <div className="w-[50px] h-[50px] rounded-[50%] mr-8 border-2 relative overflow-hidden">
        <img
          alt="Sua foto"
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none"
          src={item[prop] ?? Avatar}
        />
      </div>
    );
  }

  let content = <div className="w-full">{innerContent}</div>;

  if (link) {
    content = (
      <button type="button" onClick={() => link(item)}>
        {innerContent}
      </button>
    );
  }

  return (
    <div
      className="flex"
      style={{
        width: width,
      }}
    >
      <div className="flex flex-col w-full">
        {label && <div className="text-[14px] text-[#8A92A6]">{label}</div>}
        {content}
      </div>
    </div>
  );
};

export default CardColuna;
