import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";

import { throttle } from "lodash";

import { saveState, loadState } from "./localStorage";

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
});

store.subscribe(throttle(() => saveState(store.getState()), 1000));

export default store;
