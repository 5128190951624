import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Usuario } from "./Cadastro";
import usuarioApi from "../../utility/api/usuarioApi";
import { selectUserToken } from "../../features/user";
import useQuery from "../../utility/useQuery";
import useAccessProfile from "../../hooks/useAccessProfile";
import { useNavigate } from "react-router-dom";
import { NotAllowed } from "../NotAllowed";
import Loading from "../../components/Loading";
import useRequest, { Status } from "../../hooks/useRequest";
import { useTranslation } from "react-i18next";

export const EdicaoUsuario = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const { isManager } = useAccessProfile();

  const query = useQuery();
  const token = useSelector(selectUserToken);
  const [toggletEnabled, setToggleEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadError, setShowUploadError] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let userId = query.get("userId");

  const usuarioRequest = useRequest(() =>
    usuarioApi.buscarUsuario(userId, token)
  );
  const atualizarUsuarioRequest = useRequest((data) =>
    usuarioApi.atualizarUsuario(data, token)
  );

  useEffect(() => {
    usuarioRequest.execute();
  }, []);

  useEffect(() => {
    if (usuarioRequest.data) {
      const usuario = usuarioRequest.data;
      //console.log(usuario);
      reset({
        nome: usuario.nome,
        login: usuario.login,
        email: usuario.email,
        divisaoId: usuario.divisionId,
        employeeId: usuario.employeeId,
        departamentos: usuario.departments.map((d) => d.id),
        divisionProfiles: usuario.divisionProfiles,
        divisionsSciId: usuario.divisionsSci.map((d) => d.id),
      });
    }
  }, [usuarioRequest.data]);

  useEffect(() => {
    setIsLoading(
      usuarioRequest.status === Status.Loading ||
        atualizarUsuarioRequest.status === Status.Loading
    );

    switch (atualizarUsuarioRequest.status) {
      case Status.Failed:
        sendDelayedMessage({
          title: `${t('components.attention')}`,
          text:
            (atualizarUsuarioRequest.data && atualizarUsuarioRequest.data[0]) ||
            "Algo deu errado",
          icon: "info",
        });
        break;
      case Status.Success:
        sendDelayedMessage({
          title: `${t('addOrUpdateUser.modalSucess.title')}`,
          text: `${t('addOrUpdateUser.modalSucess.text')}`,
          icon: "success",
        });
        navigate("/usuario/listagem");
        break;
      default:
        break;
    }
  }, [
    usuarioRequest.status,
    atualizarUsuarioRequest.status,
    atualizarUsuarioRequest,
    navigate,
  ]);

  const cancelForm = () => {
    Swal.fire({
      text: `${t('components.msgConfirmCanceEdit')}`,
      showDenyButton: true,
      confirmButtonText: `${t('modalUser.yes')}`,
      denyButtonText: `${t('modalUser.no')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        resetForm();

        Swal.fire({
          text: `${t('components.msgCancel')}`,
          icon: "success",
        });
      }
    });
  };

  const resetForm = () => {
    reset();
    setToggleEnabled(true);
  };

  const sendDelayedMessage = (message, isLoading) => {
    setTimeout(
      () => {
        Swal.fire(message);
        setIsLoading(false);
      },
      isLoading ? 1000 : 0
    );
  };

  const onSubmit = async (data) => {
    atualizarUsuarioRequest.execute({
      id: userId,
      nome: data.nome,
      login: data.login,
      email: data.email,
      divisionId: data.divisaoId,
      employeeId: data.employeeId,
      departmentIds: data.departamentos,
      divisionsSciId: data.divisionsSciId,
      divisionProfiles: (data.divisionProfiles || []).map((d) => ({
        divisionId: d.divisionId,
        profileIds: d.profiles.map((p) => p.id),
      })),
    });
  };

  if (!isManager) return <NotAllowed />;

  return (
    <>
      <Loading isVisible={isLoading} />
      <Usuario
        title={t('addOrUpdateUser.tabs.user.title')}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        toggletEnabled={toggletEnabled}
        setToggleEnabled={setToggleEnabled}
        toggleIsVisible={false}
        isLoading={isLoading}
        resetForm={cancelForm}
        showUploadError={showUploadError}
        setShowUploadError={setShowUploadError}
        hasStatus={false}
        isLoginDisabled={true}
      />
    </>
  );
};
