import DropdownMultiple from "./DropdownMultiple";
import DropdownSingle from "./DropdownSingle";

export const dropdownPosition = {
  Top: 0,
  Bottom: 1,
};

const Dropdown = (props) => {
  if (props.multiple) {
    return <DropdownMultiple {...props} />;
  }

  return <DropdownSingle {...props} />;
};

export default Dropdown;
