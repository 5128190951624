import React, { useState, useEffect } from "react";
import iconOpenMenu from "../../../assets/imagens/png/chevron-double-right.png";
import iconDashBoard from "../../../assets/imagens/png/Home.png";
import iconConfig from "../../../assets/imagens/png/Configuracoes.png";
import iconConfigBlue from "../../../assets/imagens/png/Configuracoes-blue.png";
import iconModules from "../../../assets/imagens/png/modules.png";
import iconModulesBlue from "../../../assets/imagens/png/modules-blue.png";
import iconValgroup from "../../../assets/imagens/png/icone-valgroup.png";
import SubMenu from "./SubMenu";
import MenuText from "./MenuText";
import MenuImage from "./MenuImage";
import HeaderMenu from "./HeaderMenu";
import Modulo from "./Modulo";
import { useNavigate } from "react-router-dom";
import useAccessProfile from "../../../hooks/useAccessProfile";
import { useTranslation } from "react-i18next";

const MenuLeft = ({ urlPortal }) => {
  const [isVisibleMenu, setIsVisibleShowMenu] = useState(false);
  const [isVisibleSubMenuModulo, isSetVisibleSubMenuModulo] = useState(false);
  const [isVisibleSubMenuUser, isSetVisibleSubMenuUser] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isManager, modules, accessToken } = useAccessProfile();


  const configMenu = [
    {
      title: `${t('menu.home')}`,
      icon: iconDashBoard,
      visible: true,
      action: () => {
        navigate(urlPortal);
      },
    },
    {
      title: `${t('menu.applications')}`,
      icon: iconModules,
      visible: !isManager,
      action: () => {
        setIsVisibleShowMenu(true);
        isSetVisibleSubMenuModulo(true);
        isSetVisibleSubMenuUser(false);
      },
    },
    {
      title: `${t('menu.managePortal')}`,
      icon: iconConfig,
      visible: isManager,
      action: () => {
        setIsVisibleShowMenu(true);
        isSetVisibleSubMenuUser(!isVisibleSubMenuUser);
        isSetVisibleSubMenuModulo(false);
      },
    },
  ];

  //TODO CARREGAR DO BACK
  const loadModuleWithLang = (modules, url, accessToken) => {
    switch(modules) {
      case "Matriz de Habilidades":
        return `${url}/login?token=${accessToken}&lang=${window.localStorage.i18nextLng}`;

      case "Skills Matrix":
        return `${url}/login?token=${accessToken}&lang=${window.localStorage.i18nextLng}`;
      
      case "Portal do Cliente":
        return `${url}/login?token=${accessToken}&lang=${window.localStorage.i18nextLng}`;
      
      case "Customer Portal":
        return `${url}/login?token=${accessToken}&lang=${window.localStorage.i18nextLng}`;
      
      default:
        return `${url}/login?token=${accessToken}`;
    }
  }

  return (
    <div>
      <div className="fixed flex flex-row z-20">
        <div className="bg-[#1B2646] z-20 h-screen w-14 mt-14 pt-2">
          <div className="h-12 flex justify-center">
            {!isVisibleMenu && (
              <button
                onClick={() => {
                  setIsVisibleShowMenu(!isVisibleMenu);
                }}
              >
                <div>
                  <img alt="" src={iconOpenMenu} width="24px" height="24px" />
                </div>
              </button>
            )}
            {isVisibleMenu && (
              <button>
                <div>
                  <img alt="" src={iconValgroup} width="24px" height="24px" />
                </div>
              </button>
            )}
          </div>
          <MenuImage configMenu={configMenu} />
        </div>

        <div
          className={`bg-[#1B2646] z-10 h-screen w-56 mt-14 pt-2 absolute ${!isVisibleMenu ? "  -translate-x-full " : ""
            } duration-500 left-14 `}
        >
          <HeaderMenu
            closeMenu={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          />
          <MenuText configMenu={configMenu} />
        </div>

        <SubMenu
          isVisibleSubMenuModulo={isVisibleSubMenuModulo}
          text={t('menu.applications')}
          icon={iconModulesBlue}
        >
          {modules.map((m) => (
            <Modulo
              text={m.name}
              isNewTab={true}
              link={loadModuleWithLang(m.name, m.url, accessToken)}
              onClick={() => {
                setIsVisibleShowMenu(false);
                isSetVisibleSubMenuModulo(false);
              }}
              key={m.id}
            />
          ))}

          {/* <Modulo
            text="OPOnline"
            isNewTab={true}
            link="/modulo/carregar-modulo?moduloId=10"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
            }}
          />
          <Modulo text="Ficha Técnica" />
          <Modulo text="Gestão de manga" /> */}
        </SubMenu>

        <SubMenu
          isVisibleSubMenuModulo={isVisibleSubMenuUser}
          text={t('menu.managePortal')}
          icon={iconConfigBlue}
        >
          <Modulo
            text={t('menu.pages.users')}
            link="/usuario/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          />
          <Modulo
            text={t('menu.pages.department')}
            link="/departamento/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          />
          {/* <Modulo
            text="Divisões"
            link="/divisao/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          /> */}
          {/* <Modulo
            text="Módulos"
            link="/modulo/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          /> */}
          {/* <Modulo
            text="Permissões"
            link="/permissao/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          /> */}
          <Modulo
            text={t('menu.pages.accessProfile')}
            link="/perfilacesso/listagem"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          />
          {/* <>
            <Modulo
              text="Países"
              link="/pais/listagem"
              onClick={() => {
                setIsVisibleShowMenu(false);
                isSetVisibleSubMenuModulo(false);
                isSetVisibleSubMenuUser(false);
              }}
            />
            <Modulo
              text="Estados"
              link="/estado/listagem"
              onClick={() => {
                setIsVisibleShowMenu(false);
                isSetVisibleSubMenuModulo(false);
                isSetVisibleSubMenuUser(false);
              }}
            />
            <Modulo
              text="Cidades"
              link="/cidade/listagem"
              onClick={() => {
                setIsVisibleShowMenu(false);
                isSetVisibleSubMenuModulo(false);
                isSetVisibleSubMenuUser(false);
              }}
            />
          </> */}
        </SubMenu>
      </div>
      <div>
        {isVisibleMenu && (
          <div
            className="fixed z-10 top-14 left-0 w-full h-full bg-[#00000071] flex justify-center items-center"
            onClick={() => {
              setIsVisibleShowMenu(false);
              isSetVisibleSubMenuModulo(false);
              isSetVisibleSubMenuUser(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default MenuLeft;
