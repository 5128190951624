import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import usuarioApi from "../../utility/api/usuarioApi";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { NotAllowed } from "../NotAllowed";
import useAccessProfile from "../../hooks/useAccessProfile";
import { useTranslation } from "react-i18next";

export const ConsultaUsuarios = () => {
  const token = useSelector(selectUserToken);
  const { t } = useTranslation();
  const { isManager } = useAccessProfile();

  const colunas = [
    {
      label: `${t('pageUser.list.column.user')}`,
      prop: "userName",
      width: "23%",
    },
    {
      label: `${t('pageUser.list.column.login')}`,
      prop: "login",
      width: "23%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: usuarioApi.listarUsuarios,
      },
      onExcluir: {
        fetchFunction: usuarioApi.excluirUsuario,
        questionText: `${t('modalUser.title')}`,
        successText: `${t('modalUser.confirm')}`,
      },
      editarLink: (item) => `/usuario/editar?userId=${item.id}`,
      showExclur: false,
    };
  }, [t]);

  const filtros = [
    {
      label: `${t('pageUser.buttons.filter.fields.name.tile')}`,
      placeholder: `${t('pageUser.buttons.filter.fields.name.placeholder')}`,
      name: "queryNome",
    },
  ];

  const consulta = useConsulta(token, events);

  if (!isManager) return <NotAllowed />;

  return (
    <div className="ml-10">
      <Consulta
        title={t('pageUser.title')}
        adicionarLink={"/usuario/cadastro"}
        showOptions={true}
        colunas={colunas}
        consulta={consulta}
        hasStatus={false}
        filtros={filtros}
      />
    </div>
  );
};
