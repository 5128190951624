import api from "./index";

const estadoApi = {
  listarEstados: async (data, token) => {
    return await api.get(`/Estado/Listar`, data, token);
  },
  cadastrarEstado: async (data, token) => {
    return await api.post(`/Estado/Cadastrar`, data, token);
  },
  atualizarEstado: async (data, token) => {
    return await api.put(`/Estado/Editar`, data, token);
  },
  buscarEstado: async (EstadoId, token) => {
    return await api.get(`/Estado/Buscar`, { EstadoId }, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Estado/ListarDropdown`, data, token);
  },
};

export default estadoApi;
