import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const NotAllowed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Swal.fire({
      icon: "error",
      title: "Erro!",
      text: "Você não tem permissão para cessar essa tela!",
    }).then(() => {
      navigate("/");
    });
  }, [navigate]);

  return <></>;
};
