import iconCloseMenu from "../../../assets/imagens/png/chevron-double-left.png";
import { useTranslation } from "react-i18next";

const HeaderMenu = ({ closeMenu }) => {
  const { t } = useTranslation();

  return (
    <button
      className="h-12 flex flex-row justify-center items-center"
      onClick={closeMenu}
    >
      <div className="font-semibold text-white text-[22px]">
        {t('nameSystem')}
      </div>
      <div className="pl-4">
        <img alt="" src={iconCloseMenu} className="w-[24px] h-[24px]" />
      </div>
    </button>
  );
};
export default HeaderMenu;
