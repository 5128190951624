const initialState = {
  id: "",
  token: "",
  userNome: "",
  login: "",
  email: "",
  type: 1,
  departamentos: [],
  isLogged: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "user/logged-in": {
      return {
        id: action.payload.data.userId,
        token: action.payload.token,
        accessToken: action.payload.accessToken,
        userNome: action.payload.data.name,
        login: action.payload.data.login,
        email: action.payload.data.login,
        type: action.payload.data.userType,
        departamentos: action.payload.data.departaments,
        modules: action.payload.modules,
        isLogged: true,
      };
    }
    case "user/updated": {
      return {
        ...state,
        userNome: action.payload.data.name,
        login: action.payload.data.login,
        email: action.payload.data.login,
      };
    }
    case "user/first-access": {
      return {
        ...state,
        isFirstAccess: false,
      };
    }
    case "user/logged-out": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
