import * as Tabs from "@radix-ui/react-tabs";
import Dropdown from "../../../components/Dropdown";
import { useTranslation } from "react-i18next";

export const MatrizTab = ({
  employees,
  register,
  setValue,
  getValues,
  watch,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Tabs.Content value="matriz" className="w-full !bg-transparent">
      <div className="flex bg-white rounded-md border border-[#ECECEC] rounded-tl-none flex-col p-4">
        <div className="my-3">
          <h5 className="text-[#8A92A6]">{t('addOrUpdateUser.tabs.matriz.caption')}</h5>
        </div>
        <div>
          <div>
            <Dropdown
              defaultText={t('addOrUpdateUser.tabs.matriz.fields.employee.placeholder')}
              options={[...employees]}
              name="employeeId"
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              label={t('addOrUpdateUser.tabs.matriz.fields.employee.label')}
              required={false}
              errors={errors}
              multiple
            />
          </div>
        </div>
      </div>
    </Tabs.Content>
  );
};
