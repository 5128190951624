import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Input from "../../components/Input";
import PerfilAcessoCollapse from "./PerfilAcessoCollapse";
import CancelarButton from "../../components/CancelarButton";
import useQuery from "../../utility/useQuery";
import moduloApi from "../../utility/api/moduloApi";
import { selectUserToken } from "../../features/user";
import { NotAllowed } from "../../hooks/useAthorize";
import useAccessProfile from "../../hooks/useAccessProfile";
import { useEffect } from "react";
import { useState } from "react";
import useRequest, { Status } from "../../hooks/useRequest";
import perfilAcessoApi from "../../utility/api/perfilAcessoApi";
import Loading from "../../components/Loading";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CadastroPerfilAcesso = () => {
  const { t } = useTranslation();
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const {
    handleSubmit,
    watch,
    getValues,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState();
  const [updatedModules, setUpdatedModules] = useState([]);

  const { isManager } = useAccessProfile();

  const modulesRequest = useRequest(() => moduloApi.listarModulos({}, token));
  const createProfileRequest = useRequest((data) =>
    perfilAcessoApi.cadastrarPerfilAcesso(data, token)
  );

  useEffect(() => {
    modulesRequest.execute();
  }, []);

  useEffect(() => {
    if (modulesRequest.data) {
      setModules(modulesRequest.data);
    }
  }, [modulesRequest.data]);

  useEffect(() => {
    setLoading(createProfileRequest.status === Status.Loading);
    
    if (createProfileRequest.status === Status.Success) {
      resetForm();
      Swal.fire({
        title: `${t('addOrUpdateUser.modalSucess.title')}`,
        text: `${t('addOrUpdateUser.modalSucess.text')}`,
        icon: "success",
      });
      navigate("/perfilacesso/listagem");
    }
  }, [createProfileRequest.status, t, navigate]);

  const onSubmit = (data) => {
    createProfileRequest.execute({
      ...data,
      modules: updatedModules,
    });
  };

  const resetForm = useCallback(() => {
    Swal.fire({
      text: `${t('components.msgConfirmCancel')}`,
      showDenyButton: true,
      confirmButtonText: `${t('modalUser.yes')}`,
      denyButtonText: `${t('modalUser.no')}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        reset();

        Swal.fire({
          text: `${t('components.msgCancel')}`,
          icon: "success",
        });
      }
    });
  }, [reset]);

  if (!isManager) return <NotAllowed />;

  if (createProfileRequest.status === Status.Success) {
    return <Navigate to="/perfilacesso/listagem" />;
  }

  return (
    <>
      {loading && <Loading isVisible={true} />}
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="w-full min-h-screen flex flex-col pb-[60px]">
          <div className="flex flex-col justify-center pt-[100px] mx-[15%]">
            <div className="mb-4 text-[24px] font-semibold text-[#1B2646]">
              {t('addOrUpdateProfileAccess.column.add.title')}
            </div>
            <div>
              <label>{t('addOrUpdateProfileAccess.column.add.field.title')}</label>
              <Input
                name="name"
                register={register}
                errors={errors}
                required={true}
                maxLength="30"
              />
            </div>
          </div>

          <div className="flex-grow bg-white rounded-md border border-[#ECECEC] py-4 px-11 mt-6 mx-[15%]">
            {modules && (
              <PerfilAcessoCollapse
                modulos={modules}
                modulosDoPerfil={[]}
                onCheck={(updMods) => setUpdatedModules(updMods)}
              />
            )}
          </div>

          <div className="flex justify-center mt-6">
            <div className="w-[70%] undefined bg-white rounded-md border border-[#ECECEC] py-4 flex items-center justify-between px-11">
              <CancelarButton
                voltar={() => navigate("/perfilacesso/listagem")}
                watch={watch}
                getValues={getValues}
                reset={resetForm}
                wide
              />
              <button className="w-[181px] h-[44px] bg-[#187733] text-white rounded-[4px]">
                {t('components.buttons.save')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CadastroPerfilAcesso;
