import { useDispatch } from "react-redux";
import { userLoggedOut } from "../features/user";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

const Logout = () => {
  const dispatch = useDispatch();

  dispatch(userLoggedOut());

  // Swal.fire({
  //   title: "Atenção!",
  //   text: "Logout realizado com sucesso!",
  //   icon: "info",
  // });

  return <Navigate to="/Conta/Login" />;
};

export default Logout;
