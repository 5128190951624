import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { selectUserToken } from "../features/user";
import { Navigate } from "react-router-dom";

export const permissoes = {
  usuarioFull: "Usuario-Full",
  usuarioConsulta: "Usuario-Consulta",
  moduloFull: "Modulo-Full",
  moduloConsulta: "Modulo-Consulta",
  departamentoFull: "Departamento-Full",
  departamentoConsulta: "Departamento-Consulta",
  localizacaoFull: "Localizacao-Full",
  localizacaoConsulta: "Localizacao-Consulta",
  divisaoFull: "Divisao-Full",
  divisaoConsulta: "Divisao-Consulta",
};

export const NotAllowed = () => {
  Swal.fire({
    title: "Não Autorizado!",
    icon: "error",
  });

  return <Navigate to="/" />;
};

export const useAthorize = (...claims) => {
  const token = useSelector(selectUserToken);
  var decoded_token = jwt_decode(token);

  let isAllowed =
    Object.keys(decoded_token).filter((item) => claims.indexOf(item) >= 0)
      .length > 0;

  return isAllowed;
};

export const checarPermissao = (token, ...permissoes) => {
  var decoded_token = jwt_decode(token);

  return (
    Object.keys(decoded_token).filter((item) => permissoes.indexOf(item) >= 0).length > 0
  );
};
