import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import moduloApi from "../../utility/api/moduloApi";
import useQuery from "../../utility/useQuery";
import Swal from "sweetalert2";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Toggle from "../../components/Toggle";
import ButtonClasses from "../../components/Button/typing";
import CancelarButton from "../../components/CancelarButton";
import { NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const EdicaoModulo = ({ setIsVisible, onFinished }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  
  let isAllowed = useAthorize(
    permissoes.moduloConsulta,
    permissoes.moduloFull
  );

  const query = useQuery();
  let moduloId = parseInt(query.get("moduloId") ?? 0);

  const token = useSelector(selectUserToken);
  const [divisoes, setDivisoes] = useState([]);
  const [modulo, setModulo] = useState({});

  const onSubmit = useCallback(
    async ({ nome, divisoes, link, status }) => {
      let data = {
        id: moduloId,
        nome,
        divisoes,
        link,
        isDisabled: !status,
      };

      let result = await moduloApi.atualizarModulo(data, token);

      if (result.hasError) {
        Swal.fire({
          title: "Erro!",
          text: "Algo deu errado",
          icon: "error",
        });
        return;
      }

      if (result.data.length === 0) {
        reset({
          ...data,
          status,
        });
        onFinished?.();

        Swal.fire({
          title: "Sucesso!",
          text: "Cadastrado com sucesso",
          icon: "success",
        });
        return;
      }

      Swal.fire({
        title: "Erro!",
        text: result.data,
        icon: "error",
      });
    },
    [moduloId, onFinished, reset, token]
  );

  const fetchDivisoes = useCallback(async () => {
    var result = await moduloApi.buscarDivisoes(token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    setDivisoes(result.data);
  }, [token]);

  const fetchModulo = useCallback(async () => {
    var result = await moduloApi.buscarModulo(moduloId, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    reset({
      ...result.data,
      status: result.data.status === "Ativo",
    });
    setModulo(result.data);
  }, [moduloId, reset, token]);

  useEffect(() => {
    fetchDivisoes();
    fetchModulo();
  }, [fetchModulo, fetchDivisoes]);

  var footer = (
    <>
      <CancelarButton
        watch={watch}
        reset={() =>
          Swal.fire({
            text: "Realmente deseja cancelar o cadastro?",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              reset();

              Swal.fire({
                text: "Cancelado com sucesso!",
                icon: "success",
              });
            }
          })
        }
        getValues={getValues}
        voltar={() => setIsVisible()}
      />
      <Button
        colorClass={ButtonClasses.DarkGreen}
        text="Salvar"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Modal
      setModal={setIsVisible}
      title="Edição de Módulo"
      footer={footer}
      height="h-auto"
      heightBody="h-[64%] py-6"
    >
      <div className="w-full h-full flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-[86%]">
          <div className="grid grid-cols-1 gap-8">
            <Input
              label="Nome"
              name="nome"
              type="text"
              placeholder="Insira o nome do Módulo"
              register={register}
              required
              errors={errors}
            />
            <Dropdown
              name="divisoes"
              defaultText="Selecione a divisao"
              defaultValue={modulo.divisaoId}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              options={divisoes}
              width={"w-full"}
              label="Divisão"
              errors={errors}
              required
              multiple
            />
            <Input
              label="Link"
              name="link"
              type="text"
              placeholder="Insira o link do Módulo"
              register={register}
              required
              errors={errors}
            />
          </div>
          <div className="pt-2">
            <Toggle
              register={register}
              setValue={setValue}
              watch={watch}
              name="status"
              defaultValue={modulo.status}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EdicaoModulo;
