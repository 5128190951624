import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";

export default function Toggle({
  register,
  setValue,
  watch,
  name,
  defaultValue,
}) {
  const [isEnabled, setIsEnabled] = useState(defaultValue ?? false);

  useEffect(() => {
    const subscription = watch((value, { name: watchName, type }) => {
      setIsEnabled(value[name] ?? defaultValue);
    });
    return () => subscription.unsubscribe();
  }, [defaultValue, name, watch]);

  const onToggle = () => {
    setIsEnabled(!isEnabled);
    setValue(name, !isEnabled, {
      shouldTouch: true,
    });
  };

  return (
    <div>
      <input {...register("status")} className="hidden" type="checkbox" />
      <Switch
        checked={isEnabled}
        onChange={() => onToggle()}
        className={`${isEnabled ? "bg-[#187733]" : "bg-[#9B9B9B]"}
          relative inline-flex h-[28px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${isEnabled ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg right-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}
