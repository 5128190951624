import api from "./index";

const moduloApi = {
  listarModulos: async (data, token) => {
    return await api.get(`/Module/List`, data, token);
  },
  cadastrarModulo: async (data, token) => {
    return await api.post(`/Modulo/Cadastrar`, data, token);
  },
  atualizarModulo: async (data, token) => {
    return await api.put(`/Modulo/Editar`, data, token);
  },
  inativarModulo: async (id, token) => {
    return await api.put(`/Modulo/Inativar`, { id }, token);
  },
  ativarModulo: async (id, token) => {
    return await api.put(`/Modulo/Ativar`, { id }, token);
  },
  excluirModulo: async (id, token) => {
    return await api.delete(`/Modulo/Excluir`, { ModuloId: id }, token);
  },
  buscarModulo: async (id, token) => {
    return await api.get(`/Modulo/Buscar`, { id }, token);
  },
  buscarDivisoes: async (token) => {
    return await api.get(`/Divisao/ListarDropdown`, null, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Modulo/ListarDropdown`, data, token);
  },
};

export default moduloApi;
