import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import WifiAnimation from "./WifiAnimation";
import Ex from "../../assets/imagens/ex.png";
import api from "../api";

const MySwal = withReactContent(Swal);

export const showErrorModal = () => {
  MySwal.fire({
    html: <ModalContent />,
    confirmButtonText: "Ok!",
    confirmButtonColor: "#187733",
  }).then(() => {
    window.location.reload();
  });
};

const ModalContent = () => {
  return (
    <div>
      <div className="relative mb-2">
        <div className="absolute right-1/2 bottom-0 z-10 translate-x-[28px]">
          <img src={Ex} alt="Xis" />
        </div>
        <WifiAnimation />
      </div>
      <p className="text-[#1B2646] font-bold leading-8 text-2xl mb-2">
        Sem conexão <br /> com a internet
      </p>
      <p className="text-[#187733] font-semibold text-lg">Tente novamente</p>
    </div>
  );
};

export const checkInternetConnection = async () => {
  if (sessionStorage.hasShowError) return;

  sessionStorage.hasShowError = true;
  const response = await api.get("/Home/Index");

  if (response.hasError) {
    showErrorModal();
    return false;
  } else {
    sessionStorage.hasShowError = '';
  }

  return true;
};
