import { useMemo } from "react";
import { useSelector } from "react-redux";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import divisaoApi from "../../utility/api/divisaoApi";
import {
  checarPermissao,
  NotAllowed,
  permissoes,
  useAthorize,
} from "../../hooks/useAthorize";

const ConsultaDivisoes = () => {
  let isAllowed = useAthorize(
    permissoes.divisaoConsulta,
    permissoes.divisaoFull
  );

  const token = useSelector(selectUserToken);
  let isEdicaoAllowed = checarPermissao(token, permissoes.divisaoFull);

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise pelo nome",
      name: "nome",
    },
    {
      label: "Cidade",
      placeholder: "Pesquise pela cidade",
      name: "cidade",
    },
    {
      label: "Referência",
      placeholder: "Pesquise pela referência",
      name: "referencia",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "10%",
    },
    {
      label: "Razão Social",
      prop: "razaoSocial",
      width: "30%",
    },
    {
      label: "País",
      prop: "pais",
      width: "15%",
    },
    {
      label: "Cidade",
      prop: "cidade",
      width: "15%",
    },
    {
      label: "Estado",
      prop: "estado",
      width: "18%",
    },
    {
      label: "Referência",
      prop: "referencia",
      width: "15%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: divisaoApi.listarDivisoes,
      },
      onAtivar: {
        fetchFunction: divisaoApi.ativarDivisao,
        successText: "Divisão ativada",
      },
      onInativar: {
        fetchFunction: divisaoApi.inativarDivisao,
        successText: "Divisão inativada",
      },
      onExcluir: {
        fetchFunction: divisaoApi.excluirDivisao,
        questionText: "Realmente deseja excluir essa divisão?",
        successText: "Divisão excluída com sucesso!",
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) => `/divisao/editar?divisaoId=${item.id}`,
    };
  }, []);

  const consulta = useConsulta(token, events);

  if (!isAllowed) return <NotAllowed />;

  return (
    <Consulta
      title="Divisões"
      adicionarLink={isEdicaoAllowed && "/divisao/cadastro"}
      colunas={colunas}
      filtros={filtros}
      consulta={consulta}
      showOptions={isEdicaoAllowed}
    />
  );
};

export default ConsultaDivisoes;
