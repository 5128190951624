import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import { FiltroTipo } from "../../components/Consulta/typing";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import moduloApi from "../../utility/api/moduloApi";
import permissaoApi from "../../utility/api/permissaoApi";
import useQuery from "../../utility/useQuery";
import CadastroPermissao from "./CadastroPermissao";
import EdicaoPermissao from "./EdicaoPermissao";
import {
  checarPermissao,
  NotAllowed,
  permissoes,
  useAthorize,
} from "../../hooks/useAthorize";

const ConsultaPermissao = () => {
  const token = useSelector(selectUserToken);

  let isAllowed = useAthorize(
    permissoes.usuarioConsulta,
    permissoes.usuarioFull
  );

  let isEdicaoAllowed = checarPermissao(token, permissoes.usuarioFull);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/permissao/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/permissao/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = useMemo(() => [
    {
      label: "Nome",
      placeholder: "Pesquise pelo nome da permissão",
      name: "nome",
    },
    {
      label: "Módulo",
      placeholder: "Pesquise pelo módulo",
      name: "moduloId",
      tipo: FiltroTipo.Select,
      fetch: () => moduloApi.listarDropdown({}, token),
    },
  ], [token]);

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "50%",
    },
    {
      label: "Módulo",
      prop: "modulo",
      width: "50%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: permissaoApi.listarPermissoes,
      },
      onAtivar: {
        fetchFunction: permissaoApi.ativarPermissao,
        successText: "Permissão ativada",
      },
      onInativar: {
        fetchFunction: permissaoApi.inativarPermissao,
        successText: "Permissão inativada",
      },
      onExcluir: {
        fetchFunction: permissaoApi.excluirPermissao,
        questionText: "Realmente deseja excluir esta permissão?",
        successText: "Permissão excluída com sucesso!",
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) =>
        `/permissao/listagem?page=${pagina}&permissaoId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroPermissao
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoPermissao
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Consulta
      title="Permissões"
      adicionarButton={isEdicaoAllowed && adicionarButton}
      showOptions={isEdicaoAllowed}
      colunas={colunas}
      filtros={filtros}
      consulta={consulta}
    />
  );
};

export default ConsultaPermissao;
