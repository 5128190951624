import { useState } from "react";
import { useTranslation } from "react-i18next";

const Dropdown = ({ userImagem, user }) => {
  const [isVisible, setIsVisible] = useState(false);

  const departamentos = Object.values(user.departamentos || {}).join(", ");

  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className="flex relative justify-center mr-5 cursor-pointer m-f1:mr-0"
        onClick={() => setIsVisible(!isVisible)}
        onBlur={() => setIsVisible(false)}
      >
        <div className="flex m-f1:py-2 t-f1:py-1">
          <div className="rounded-full overflow-hidden border-2 relative mr-5 m-f1:w-8 m-f1:h-8 m-f1:mr-3 t-f1:h-12 t-f1:w-12">
            <img
              alt="Sua Foto"
              src={userImagem}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none"
            />
          </div>
          <div className="flex flex-col text-left justify-center m-f1:hidden t-f1:flex">
            <h5 className="p-0 text-lg font-medium leading-5 whitespace-nowrap text-ellipsis max-w-sm overflow-hidden">
              {user.userNome}
            </h5>
            <span className="text-xs whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">
              {departamentos.length ? departamentos : `${t('nameAccess')}`}
            </span>
          </div>
          <div className="h-full ml-2 flex items-start py-[10px] m-f1:hidden t-f1:flex">
            {!isVisible && (
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
            )}
            {isVisible && (
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-caret-up-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              </div>
            )}
          </div>
        </div>
        {isVisible && (
          <div className="absolute top-full left-0 w-[200px] mt-4 rounded-md bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 m-f1:hidden t-f1:flex">
            <ul className={`w-full`}>
              <li className="flex items-center text-[#676767] px-4 py-2 text-[14px] border-b cursor-default">
                <div className="max-w-[25px] max-h-[25px] border rounded-[50%] mr-3">
                  <img alt="Sua Foto" src={userImagem} />
                </div>
                <span className="text-left">{user.userNome}</span>
              </li>
            </ul>
          </div>
        )}
      </button>
    </div>
  );
};

export default Dropdown;
