import React from "react";
import CardAction from "./CardAction";
import CardColuna from "./CardColuna";
import { useTranslation } from "react-i18next";

const Card = ({
  item,
  colunas,
  index,
  search,
  reset,
  cardOptionsIndex,
  setCardOptionsIndex,
  events,
  showOptions,
  hasLinkServiceAide
}) => {
  const { t } = useTranslation();
  var lng = localStorage.getItem("i18nextLng");
  
  var background = '';

  switch(item.status) {
    case `${t('addOrUpdateProfileAccess.column.status.active')}`: 
      background = `bg-[#187733] ${lng === "pt-BR" ? "px-2" : "px-1"}` 
      break;

    case `${t('addOrUpdateProfileAccess.column.status.inactive')}`: 
      background = `bg-[#AF0011] ${lng === "pt-BR" ? "px-2" : "px-1"}`
      break;

    case `${t('addOrUpdateProfileAccess.column.status.new')}`: 
      background = `bg-[#B2B2B2] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;

    case `${t('addOrUpdateProfileAccess.column.status.complete')}`: 
      background = `bg-[#187733] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;

    case `${t('addOrUpdateProfileAccess.column.status.approved')}`: 
      background = `bg-[#187733] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;

    case `${t('addOrUpdateProfileAccess.column.status.pending')}`: 
      background = `bg-[#AF0011] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;

    case `${t('addOrUpdateProfileAccess.column.status.queued')}`: 
      background = `bg-[#E9C46A] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;
    
    default:
      background = `bg-[#187733] ${lng === "pt-BR" ? "px-3" : "px-3"}`
      break;
  }

  return (
    <div className="w-full min-h-[80px] px-[2%] grid grid-cols-[1fr] t-f1:grid-cols-[minmax(88%,_1fr)_minmax(110px,_370px)] items-center justify-center mt-2 border bg-white rounded-lg">
      <div className="t-f1:flex">
        {colunas.map((coluna, index) => (
          <CardColuna key={index} configuration={coluna} item={item} />
        ))}
      </div>
      <div className="flex items-center grow justify-between">
        <div className="flex">
          {item.status && (
            <div className="flex flex-col" title={item.motivoCodigo}>
              <div className="text-[13px] text-[#8A92A6]">{t('addOrUpdateProfileAccess.column.status.title')}</div>
              <div>
                <button
                  className={`${
                    background
                  } text-[10px] py-1 font-semibold text-white rounded-md`}
                >
                  {item.status}
                </button>
              </div>
            </div>
          )}
        </div>
        {showOptions && (
          <CardAction
            index={index}
            item={item}
            search={search}
            reset={reset}
            isAtivo={item.status === `${t('addOrUpdateProfileAccess.column.status.active')}`}
            isCardOptionsVisible={cardOptionsIndex === index}
            setCardOptionsIndex={setCardOptionsIndex}
            onAtivar={events.onAtivar}
            onInativar={events.onInativar}
            onExcluir={events.onExcluir}
            // showExcluir={events.showExcluir?.(item)}
            showExcluir={true}
            editarLink={events.editarLink?.(item)}
            editarEvent={events.editarEvent}
            hasLinkServiceAide={hasLinkServiceAide}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
