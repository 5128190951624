const MenuText = ({ configMenu }) => {
  return (
    <div>
      {configMenu.map((menu, index) => (
        menu.visible ? <div key={index} className="py-8 flex flex-col justify-center">
          <div
            className="text-white text-[16px] cursor-pointer h-8 leading-8"
            onClick={menu.action}
          >
            {menu.title}
          </div>
        </div> : null
      ))}
    </div>
  );
};

export default MenuText;
