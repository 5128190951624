import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import useQuery from "../../utility/useQuery";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import ButtonClasses from "../../components/Button/typing";
import CancelarButton from "../../components/CancelarButton";
import estadoApi from "../../utility/api/estadoApi";
import paisApi from "../../utility/api/paisApi";
import Dropdown from "../../components/Dropdown";
import { NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const EdicaoEstados = ({ setIsVisible, onFinished }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  let isAllowed = useAthorize(
    permissoes.localizacaoConsulta,
    permissoes.localizacaoFull
  );

  const query = useQuery();
  let estadoId = parseInt(query.get("estadoId") ?? 0);
  const [paises, setPaises] = useState([]);

  const token = useSelector(selectUserToken);

  const onSubmit = useCallback(
    async ({ nome, paisId }) => {
      let data = {
        estadoId,
        nome,
        paisId,
      };

      let result = await estadoApi.atualizarEstado(data, token);

      if (result.hasError) {
        Swal.fire({
          title: "Erro!",
          text: "Algo deu errado",
          icon: "error",
        });
        return;
      }

      if (result.data.length === 0) {
        reset(data);
        onFinished?.();

        Swal.fire({
          title: "Sucesso!",
          text: "Cadastrado com sucesso",
          icon: "success",
        });
        return;
      }

      Swal.fire({
        title: "Erro!",
        text: result.data,
        icon: "error",
      });
    },
    [onFinished, estadoId, reset, token]
  );

  const loadPaises = useCallback(async () => {
    var result = await paisApi.listarDropdown(null, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    setPaises(result.data);
  }, [token]);

  const fetchEstado = useCallback(async () => {
    var result = await estadoApi.buscarEstado(estadoId, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    reset(result.data);
  }, [estadoId, reset, token]);

  useEffect(() => {
    fetchEstado();
    loadPaises();
  }, [fetchEstado, loadPaises]);

  var footer = (
    <>
      <CancelarButton
        watch={watch}
        reset={() =>
          Swal.fire({
            text: "Realmente deseja cancelar o cadastro?",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              reset();

              Swal.fire({
                text: "Cancelado com sucesso!",
                icon: "success",
              });
            }
          })
        }
        getValues={getValues}
        voltar={() => setIsVisible()}
      />
      <Button
        colorClass={ButtonClasses.DarkGreen}
        text="Salvar"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Modal setModal={setIsVisible} title="Edição de Estado" footer={footer}>
      <div className="w-full h-full flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-[86%]">
          <div className="grid grid-cols-1 gap-8">
            <Dropdown
              name="paisId"
              defaultText="Selecione o país"
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              options={paises}
              width={"w-full"}
              label="País"
              errors={errors}
              required
            />

            <Input
              label="Estado"
              name="nome"
              type="text"
              placeholder="Insira o nome do estado"
              register={register}
              required
              errors={errors}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EdicaoEstados;
