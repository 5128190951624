import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import moduloApi from "../../utility/api/moduloApi";
import useQuery from "../../utility/useQuery";
import CadastroModulo from "./CadastroModulo";
import EdicaoModulo from "./EdicaoModulo";
import {
  checarPermissao,
  NotAllowed,
  permissoes,
  useAthorize,
} from "../../hooks/useAthorize";

const ConsultaModulo = () => {
  let isAllowed = useAthorize(permissoes.moduloConsulta, permissoes.moduloFull);

  const token = useSelector(selectUserToken);
  let isEdicaoAllowed = checarPermissao(token, permissoes.moduloFull);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/modulo/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/modulo/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise pelo nome",
      name: "nome",
    },
    {
      label: "Divisão",
      placeholder: "Pesquise pela divisão",
      name: "divisao",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "50%",
    },
    {
      label: "Divisão",
      prop: "divisaoNome",
      width: "50%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: moduloApi.listarModulos,
      },
      onAtivar: {
        fetchFunction: moduloApi.ativarModulo,
        successText: "Módulo ativado",
      },
      onInativar: {
        fetchFunction: moduloApi.inativarModulo,
        successText: "Módulo inativado",
      },
      onExcluir: {
        fetchFunction: moduloApi.excluirModulo,
        questionText: "Realmente deseja excluir este módulo?",
        successText: "Módulo excluído com sucesso!",
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) =>
        `/modulo/listagem?page=${pagina}&moduloId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroModulo
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoModulo
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Consulta
      title="Módulos"
      adicionarButton={isEdicaoAllowed && adicionarButton}
      colunas={colunas}
      filtros={filtros}
      consulta={consulta}
      showOptions={isEdicaoAllowed}
    />
  );
};

export default ConsultaModulo;
