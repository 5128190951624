import api from "./index";

const permissaoApi = {
  listarPermissoes: async (data, token) => {
    return await api.get(`/Permissao/Listar`, data, token);
  },
  cadastrarPermissao: async (data, token) => {
    return await api.post(`/Permissao/Cadastrar`, data, token);
  },
  atualizarPermissao: async (data, token) => {
    return await api.put(`/Permissao/Editar`, data, token);
  },
  inativarPermissao: async (id, token) => {
    return await api.put(`/Permissao/Inativar`, { id }, token);
  },
  ativarPermissao: async (id, token) => {
    return await api.put(`/Permissao/Ativar`, { id }, token);
  },
  excluirPermissao: async (id, token) => {
    return await api.delete(`/Permissao/Excluir`, { PermissaoId: id }, token);
  },
  buscarPermissao: async (id, token) => {
    return await api.get(`/Permissao/Buscar`, { id }, token);
  },
  buscarDivisoes: async (token) => {
    return await api.get(`/Divisao/ListarDropdown`, null, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Permissao/ListarDropdown`, data, token);
  },
  listarDropdownSearch: async (data, token) => {
    return await api.get(`/Permissao/ListarPermissoesDropdown`, data, token);
  },
  buscarPermissoesDropdown: async (data, token) => {
    return await api.get(`/Permissao/BuscarPermissoesDropdown`, data, token);
  },
};

export default permissaoApi;
