import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

const LoginSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { t } = useTranslation();

  const moveSlides = useCallback(() => {
    let index = slideIndex + 1;

    if (index > 2) {
      index = 0;
    }
    setSlideIndex(index);
  }, [setSlideIndex, slideIndex]);

  useEffect(() => {
    const timeoutIndex = setTimeout(moveSlides, 8000);

    return () => {
      clearTimeout(timeoutIndex);
    };
  }, [moveSlides]);

  const parse = require('html-react-parser');


  return (
    <div className="grow min-w-[50%] m-f1:h-auto flex flex-col content-center d-f1:h-screen">
      <div
        className={`${slideIndex === 0 ? "flex" : "hidden"
          } grow  flex-col justify-center d-f1:h-screen bg-black text-[12px] d-f1:text-[17px] text-white py-32 d-f1:mt-0 d-f2:py-[300px] d-f1:px-16  d-f1:font-thin fade slide-1`}
      >
        <div className="w-[280px] px-6 m-f3:w-[300px] m-f3:text-[14px] m-f6:w-[350px] t-f1:w-[450px] t-f4:w-[550px] d-f1:w-full d-f1:px-0">
          <div className="font-semibold mb-10">{t('loginSlider1.title')}</div>
          <div className="mb-10">{parse(t('loginSlider1.paragraph1'))}</div>
          <div>{parse(t('loginSlider1.paragraph2'))}</div>
        </div>
        <div className="flex mt-6 px-6 d-f1:px-0">
          <div className="w-8 h-1 mr-2 rounded-sm bg-[#187733]"></div>
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
        </div>
      </div>
      <div
        className={`${slideIndex === 1 ? "flex" : "hidden"
          } flex-col justify-center m-f1:w-full m-f1:h-[800px] l:h-screen d-f1:h-screen l:mt-[90px] d-f1:mt-0 bg-black text-[12px] text-white py-4 l:py-28 d-f2:py-56 font-thin slide-2 fade`}
      >
        <div className="flex w-full px-4 t-f4:px-8">
          <div className="w-[20%] flex justify-start t-f1:justify-center l:justify-center items-center">
            <div id="agil" className="w-[42.5px] h-[32.5px]"></div>
          </div>
          <div className="w-[80%] l:w-[50%] d-f1:w-[70%]">
            <div className="text-[13px]">
              <h1 className="font-bold text-[16px]">{t('loginSlider2.agile.title')}</h1>
              {t('loginSlider2.agile.body')}
            </div>
          </div>
        </div>
        <div className="flex w-full px-4 mt-10 t-f4:px-8">
          <div className="w-[20%] flex justify-start t-f1:justify-center l:justify-center items-center">
            <div id="comprometida" className="w-[40.5px] h-[32.5px]"></div>
          </div>
          <div className="w-[80%] l:w-[50%] d-f1:w-[70%]">
            <div className="text-[13px]">
              <h1 className="font-bold text-[16px]">{t('loginSlider2.committed.title')}</h1>
              {t('loginSlider2.committed.body')}
            </div>
          </div>
        </div>
        <div className="flex w-full px-4 mt-10 t-f4:px-8">
          <div className="w-[20%] flex justify-start t-f1:justify-center l:justify-center items-center">
            <div id="focada-cliente" className="w-[44.5px] h-[39.5px]"></div>
          </div>
          <div className="w-[80%] l:w-[50%] d-f1:w-[70%]">
            <div className="text-[13px]">
              <h1 className="font-bold text-[16px]">{t('loginSlider2.focusedClient.title')}</h1>
              {t('loginSlider2.focusedClient.body')}
            </div>
          </div>
        </div>
        <div className="flex w-full px-4 mt-10 t-f4:px-8">
          <div className="w-[20%] flex justify-start t-f1:justify-center l:justify-center items-center">
            <div id="empreededora" className="w-[40.5px] h-[48.5px]"></div>
          </div>
          <div className="w-[80%] l:w-[50%] d-f1:w-[70%]">
            <div className="text-[13px]">
              <h1 className="font-bold text-[16px]">{t('loginSlider2.entrepreneur.title')}</h1>
              {t('loginSlider2.entrepreneur.body')}
            </div>
          </div>
        </div>
        <div className="flex mt-6 px-6 l:justify-start l:px-24 l:pt-4 d:pt-0 d:px-0 d:mt-0">
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
          <div className="w-8 h-1 mr-2 rounded-sm bg-[#187733]"></div>
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
        </div>
      </div>
      <div
        className={`${slideIndex === 2 ? "flex" : "hidden"
          } flex-col m-f1:justify-start l:justify-center m-f1:w-full m-f1:h-[800px] l:h-screen d-f1:h-screen l:mt-[90px] d-f1:mt-0 bg-black text-[12px] text-white py-4 l:py-32 d-f1:py-28 font-thin slide-2 fade`}
      >
        <div className="w-full px-2 m-f1:mt-10 l:mt-0">
          <div className="font-bold text-[22px] pl-5 l:pl-[90px]">{t('loginSlider3.title')}</div>
          <div className="font-semibold text-[16px] pl-5 mt-3 l:pl-[90px]">{t('loginSlider3.paragraph1')}</div>
          <div className="text-[14px] pl-5 mt-3 px-6 l:pl-[90px]">{t('loginSlider3.paragraph2')}</div>
          <div className="text-[14px] pl-5 mt-3 px-6 l:pl-[90px]">{t('loginSlider3.paragraph3')}</div>
        </div>
        <div className="flex mt-6 px-6 l:justify-start l:px-24 l:pt-4 d:pt-0 d:px-0 d:mt-0">
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
          <div className="w-8 h-1 mr-2 rounded-sm dot"></div>
          <div className="w-8 h-1 mr-2 rounded-sm bg-[#187733]"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginSlider;
