import * as Tabs from "@radix-ui/react-tabs";
import { useMemo } from "react";
import Dropdown from "../../../components/Dropdown";

export const SCITab = ({
  divisions,
  register,
  setValue,
  getValues,
  watch,
  errors,
}) => {
  const allOption = useMemo(() => {
    return {
      value: "00000000-0000-0000-0000-000000000000",
      text: "Todos",
      isFixed: true,
    };
  }, []);

  return (
    <Tabs.Content value="sci" className="w-full !bg-transparent">
      <div className="flex bg-white rounded-md border border-[#ECECEC] rounded-tl-none flex-col p-4">
        <div className="my-3">
          <h5 className="text-[#8A92A6]">Chamados SCI</h5>
        </div>
        <div>
          <div>
            <Dropdown
              defaultText="Selecione uma divisão"
              hasAllOption
              options={[...divisions, allOption]}
              name="divisionsSciId"
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              label="Divisão"
              required={true}
              errors={errors}
              multiple
            />
          </div>
        </div>
      </div>
    </Tabs.Content>
  );
};
