import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { selectUserToken } from "../../features/user";
import departamentoApi from "../../utility/api/departamentoApi";
import useQuery from "../../utility/useQuery";
import CadastroDepartamento from "./CadastroDepartamento";
import EdicaoDepartamento from "./EdicaoDepartamento";
import useAccessProfile from "../../hooks/useAccessProfile";
import { NotAllowed } from "../NotAllowed";
import { useTranslation } from "react-i18next";

const ConsultaDepartamentos = () => {
  const { t } = useTranslation();
  const token = useSelector(selectUserToken);

  const [isCadastroVisible, setIsCadastroVisible] = useState(false);
  const [isEdicaoVisible, setIsEdicaoVisible] = useState(false);

  const { isManager } = useAccessProfile();

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  let isCadastro = location.pathname === "/departamento/cadastro";
  let pagina = parseInt(query.get("page") ?? 0);

  useEffect(() => {
    if (isCadastro) setIsCadastroVisible(true);
  }, [isCadastro]);

  useEffect(() => {
    if (!isCadastroVisible) navigate("/departamento/listagem");
  }, [isCadastroVisible, navigate]);

  const filtros = [
    {
      label: `${t('addOrUpdateDepartment.filter.description.label')}`,
      placeholder: `${t('addOrUpdateDepartment.filter.description.placeholder')}`,
      name: "queryNome",
    },
  ];

  const customSCIColumnContent = (item) => (
    <span>{item.hasSCI ? `${t('modalUser.yes')}` : `${t('modalUser.no')}`}</span>
  );

  const colunas = useMemo(() => {
    return [
      {
        label: `${t('addOrUpdateDepartment.filter.columns.description')}`,
        prop: "description",
        width: "35%",
      },
      {
        label: `${t('addOrUpdateDepartment.filter.columns.division')}`,
        prop: "divisionName",
        width: "35%",
      },
      {
        label: `${t('addOrUpdateDepartment.filter.columns.haveSCI')}`,
        prop: "hasSCI",
        width: "30%",
        customContent: customSCIColumnContent,
      },
    ];
  }, []);

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: departamentoApi.listarDepartamentos,
      },
      onExcluir: {
        fetchFunction: departamentoApi.excluirDepartamento,
        questionText: `${t('addOrUpdateDepartment.action.delete.questionText')}`,
        successText: `${t('addOrUpdateDepartment.action.delete.successText')}`,
      },
      showExcluir: (item) => item.isDeletable,
      editarLink: (item) => `/departamento/editar?departmentId=${item.id}`,
      editarEvent: () => setIsEdicaoVisible(true),
    };
  }, [pagina]);

  const consulta = useConsulta(token, events);

  let adicionarButton = (
    <>
      <button
        type="button"
        onClick={() => setIsCadastroVisible(true)}
        className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
      >
        Adicionar
      </button>
      {isCadastroVisible && (
        <CadastroDepartamento
          setIsVisible={setIsCadastroVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
      {isEdicaoVisible && (
        <EdicaoDepartamento
          setIsVisible={setIsEdicaoVisible}
          onFinished={() => consulta.events.onSearch()}
        />
      )}
    </>
  );

  if (!isManager) return <NotAllowed />;

  return (
    <div className="ml-10">
      <Consulta
        title={t('addOrUpdateDepartment.title')}
        adicionarLink={"/departamento/cadastro"}
        showOptions={true}
        colunas={colunas}
        consulta={consulta}
        filtros={filtros}
      />
    </div>
  );
};

export default ConsultaDepartamentos;
