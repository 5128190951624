import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from "./Pagination";
import Filtros from "./Filtros";
import Card from "./Card";
import InformacaoNaoEncontrada from "./InformacaoNaoEncontrada";
import Loading from "../Loading";
import InformacaoNaoCadastrada from "./NenhumaInformacaoCadastrada";
import { useTranslation } from "react-i18next";

const Consulta = ({
  title,
  colunas,
  filtros,
  adicionarLink,
  adicionarButton,
  consulta,
  showOptions,
  hasLinkServiceAide,
  style,
  customTableName
}) => {
  const { t } = useTranslation();
  const { form, items, pagina, events } = consulta;
  const { register, handleSubmit, reset, setValue, watch, getValues } = form;

  const [isFiltroVisible, setIsFiltroVisible] = useState(false);
  const [cardOptionsIndex, setCardOptionsIndex] = useState(-1);

  const toggleFiltro = (isVisible) => {
    if (isFiltroVisible) {
      setTimeout(() => {
        events.onSearch();
      }, 250);
    }
    setIsFiltroVisible(isVisible);
  };

  const hasFiltro = useCallback(() => {
    return (
      Object.values(getValues()).filter((item) => Boolean(item)).length > 0
    );
  }, [getValues]);

  if (consulta.isLoading) return <Loading isVisible={true} />;

  if(items.cards === null) {
    items.cards = [];
  } 

  return (
    <div className="w-full min-h-screen flex justify-center">
      <div className="w-[92%] h-52 mt-28" style={style}>
        {/* Versão Tablet e Desktop */}
        <div className="flex justify-between m-f1:hidden t-f1:flex">
          <div className="flex items-center">
            <div className="text-[28px] font-semibold mr-4 text-[#1B2646]">
              {title}
            </div>
            <div>
              {adicionarButton ??
                (adicionarLink && (
                  <Link
                    to={adicionarLink}
                    className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                  >
                    {t('pageUser.buttons.add')}
                  </Link>
                ))}
            </div>
          </div>
          {filtros && (
            <div>
              <button
                className="bg-[#005BBF] text-[#FFF] mt-1 py-1 px-10 rounded-md hover:bg-[#1E3784]"
                onClick={() => toggleFiltro(!isFiltroVisible)}
              >
                {t('pageUser.buttons.filter.buttonTitle')}
              </button>
            </div>
          )}
        </div>
        {/* Versão Mobile */}
        <div className="text-[28px] font-semibold mr-4 text-[#1B2646] t-f1:hidden">
          {title}
        </div>
        <div className="flex justify-between mt-3 t-f1:hidden">
          <div className="mt-1">
            {adicionarButton ??
              (adicionarLink && (
                <Link
                  to={adicionarLink}
                  className="bg-[#005BBF] text-[#FFF] py-1 px-5 my-0 rounded-md hover:bg-[#1E3784]"
                >
                  {t('pageUser.buttons.add')}
                </Link>
              ))}
          </div>
          {filtros && (
            <div>
              <button
                className="bg-[#005BBF] text-[#FFF] py-1 px-10 rounded-md hover:bg-[#1E3784]"
                onClick={() => toggleFiltro(!isFiltroVisible)}
              >
                {t('pageUser.buttons.filter.buttonTitle')}
              </button>
            </div>
          )}
        </div>
        {filtros && (
          <Filtros
            id={title}
            register={register}
            handleSubmit={handleSubmit}
            configuration={filtros}
            isFiltroVisible={isFiltroVisible}
            toggleFiltro={toggleFiltro}
            search={events.onSearch}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}

        {items.cards.length > 0 && (
          <h1 className="text-[#1B2646] font-medium mt-10 text-[20px]">
            {customTableName != null ?
              <>{customTableName}</>
                :
              <>{t('pageUser.list.title')}</>
            }
          </h1>
        )}

        <div className="min-h-[440px]">
          {items.cards.length === 0 &&
            (hasFiltro() ? (
              <InformacaoNaoEncontrada />
            ) : (
              <InformacaoNaoCadastrada />
            ))}

          {items.cards.map((item, index) => (
            <Card
              key={index}
              item={item}
              colunas={colunas}
              index={index}
              search={events.onSearch}
              reset={reset}
              cardOptionsIndex={cardOptionsIndex}
              setCardOptionsIndex={setCardOptionsIndex}
              events={events}
              showOptions={showOptions}
              hasLinkServiceAide={hasLinkServiceAide}
            />
          ))}
        </div>
        {items.cards.length > 0 &&
          items.quantidadeTotal > items.quantidadeItems && (
            <div className="w-full h-[100px] flex items-center justify-end">
              <Pagination
                currentPage={pagina}
                recordsTotal={items.quantidadeTotal}
                recordsFiltered={items.quantidadeItems}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Consulta;
