import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { selectUser, selectUserToken, userLoggedIn } from "../../features/user";
import accountApi from "../../utility/api/accountApi";
import useQuery from "../../utility/useQuery";
import { useCallback, useEffect } from "react";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

const Index = () => {
    const { t } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    const tokenLogado = useSelector(selectUserToken);
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let query = useQuery();
    let returnUrl = query.get("returnUrl");
    let loginUrl = query.get("loginUrl");

    const externalLogin = useCallback(
        async (tokenModulo) => {
            if (!tokenModulo) {
                let result = await accountApi.buscarTokenModulo(
                    {
                        url: loginUrl,
                        loginUrl: user.lembrar,
                        moduloUrl: loginUrl,
                    },
                    token
                );

                if (result.hasError) {
                    Swal.fire({
                        title: "Algo deu errado!",
                        icon: "error",
                    });
                }

                tokenModulo = result.data;
            }

            tokenModulo = tokenModulo.replace("Bearer", "").trim();

            var url = new URL(loginUrl);
            url.searchParams.append("token", tokenModulo);
            window.location.href = url.href;
        },
        [loginUrl, token, user.lembrar]
    );

    useEffect(() => {
        if (loginUrl && tokenLogado) {
            externalLogin();
        } else return;
    }, [externalLogin, loginUrl, tokenLogado]);

    useEffect(() => {
        if (loginUrl) return;

        if (loginUrl) {
            Swal.fire({
                title: "Atenção!",
                text: "É necessário realizar o login para acessar esse recurso",
                icon: "info",
            });
        } else if (returnUrl) {
            // TODO verificar necessidade
            // Swal.fire({
            //   title: "Atenção!",
            //   text: "Acesso expirado",
            //   icon: "info",
            // });
        }

    }, [loginUrl, returnUrl]);

    const AuthenticatedSSO = useCallback(async () => {
        const { data } = await accountApi.loginAD({
            token
        });
        if (!data.isValid) {
            Swal.fire({
                title: `${t('attention')}`,
                text: data.message,
                icon: "info",
            });
            navigate("/");
            return;
        }

        dispatch(userLoggedIn(data));

        if (loginUrl) {
            Swal.fire({
                title: "Módulo não cadastrado!",
                text: "Tente acessar o Módulo novamente",
                icon: "info",
            });
            navigate("/");
        } else if (returnUrl) window.location.href = returnUrl;
        else navigate("/service/listagem");
    }, [dispatch, loginUrl, returnUrl, token, navigate]);

    useEffect(() => {
        AuthenticatedSSO();
    }, [AuthenticatedSSO]);

    if (loginUrl && tokenLogado) return <></>;


    return (
        <Loading isVisible={true} />
    );
};

export default Index;
