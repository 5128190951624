import Container from "./Container";

const IndexFooter = ({ width, height, texto, children, className }) => {
  return (
    <div className="flex justify-center mt-6">
      <Container width={width} height={height} custom={className}>
        {texto || children}
      </Container>
    </div>
  );
};
export default IndexFooter;
