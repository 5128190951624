import { NavLink } from "react-router-dom";

const ContainerModulo = ({ children, onClick, link, isNewTab }) => {
  return (
    <div
      className="w-full flex justify-center cursor-pointer h-[4.5rem]"
      onClick={onClick}
    >
      <a
        href={link}
        target={isNewTab ? "_blank" : undefined}
        rel={isNewTab ? "noopener noreferrer" : undefined}
        className="w-[90%] h-full bg-modules flex items-center"
      >
        {children}
      </a>
    </div>
  );
};

export default ContainerModulo;
