import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import divisaoApi from "../../utility/api/divisaoApi";
import IndexBody from "../../components/Cadastro/body/IndexBody";
import IndexFooter from "../../components/Cadastro/footer/IndexFooter";
import Input from "../../components/Input/index";
import Dropdown from "../../components/Dropdown";
import { Validator } from "../../utility/validations";
import Button from "../../components/Button";
import { selectUserToken } from "../../features/user";
import CancelarButton from "../../components/CancelarButton";
import ButtonClasses from "../../components/Button/typing";
import { useNavigate } from "react-router-dom";
import { NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const CadastroDivisao = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  let isAllowed = useAthorize(
    permissoes.divisaoConsulta,
    permissoes.divisaoFull
  );

  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  const loadDropdown = useCallback(
    async (name, setValue, requestFunction, setter, data, token) => {
      var result = await requestFunction(data, token);

      if (result.hasError) {
        Swal.fire({
          title: "Erro!",
          text: "Algo deu errado",
          icon: "error",
        });
        return;
      }

      setValue(name, "");
      setter(result.data);
    },
    []
  );

  const listarPaises = useCallback(async () => {
    loadDropdown(
      "paisId",
      setValue,
      divisaoApi.listarPaises,
      setPaises,
      {},
      token
    );
  }, [loadDropdown, setValue, token]);

  const listarEstados = useCallback(
    async (getValues) => {
      loadDropdown(
        "estadoId",
        setValue,
        divisaoApi.listarEstados,
        setEstados,
        {
          paisId: getValues("paisId"),
        },
        token
      );
    },
    [loadDropdown, setValue, token]
  );

  const listarCidades = useCallback(
    async (getValues) => {
      loadDropdown(
        "cidadeId",
        setValue,
        divisaoApi.listarCidades,
        setCidades,
        {
          paisId: getValues("paisId"),
          estadoId: getValues("estadoId"),
        },
        token
      );
    },
    [setValue, loadDropdown, token]
  );

  useEffect(() => {
    listarPaises();
    listarEstados(getValues);
    listarCidades(getValues);
  }, [getValues, listarCidades, listarEstados, listarPaises]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name !== "paisId") return;
      listarEstados(getValues);
      listarCidades(getValues);
    });
    return () => subscription.unsubscribe();
  }, [getValues, listarCidades, listarEstados, watch]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name !== "estadoId") return;
      listarCidades(getValues);
    });
    return () => subscription.unsubscribe();
  }, [getValues, listarCidades, watch]);

  const cancelForm = () => {
    Swal.fire({
      text: "Realmente deseja cancelar o cadastro?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        reset();

        Swal.fire({
          text: "Cancelado com sucesso!",
          icon: "success",
        });
      }
    });
  };

  const onSubmit = async ({ nome, cidadeId, razaoSocial, referencia }) => {
    let data = {
      nome,
      razaoSocial,
      referencia,
      cidadeId,
    };

    let result = await divisaoApi.cadastrarDivisao(data, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    if (result.data.length === 0) {
      reset();

      Swal.fire({
        title: "Sucesso!",
        text: "Cadastrado com sucesso",
        icon: "success",
      });
      return;
    }

    Swal.fire({
      title: "Erro!",
      text: result.data,
      icon: "error",
    });
  };

  if (!isAllowed) return <NotAllowed />;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full min-h-screen mb-[60px]"
    >
      <IndexBody
        width={"w-[65%]"}
        height={"min-h-[350px]"}
        title="Cadastro de Divisão"
      >
        <div className="flex h-full">
          <div className="w-full px-16 py-14 grid grid-cols-2 grid-flow-col gap-x-28 gap-y-2 grid-rows-[repeat(3,_auto)]">
            <Input
              label="Divisão"
              name="nome"
              type="text"
              placeholder="Insira a divisão"
              register={register}
              validate={(text) =>
                new Validator.Builder().required().build().validate(text)
              }
              errors={errors}
            />
            <Input
              label="Razão Social"
              name="razaoSocial"
              type="text"
              placeholder="Insira a razão social"
              register={register}
              validate={(text) =>
                new Validator.Builder().required().build().validate(text)
              }
              errors={errors}
            />
            <Input
              label="Referência"
              name="referencia"
              type="text"
              placeholder="Insira a referência"
              register={register}
              validate={(text) =>
                new Validator.Builder().required().build().validate(text)
              }
              errors={errors}
            />
            <Dropdown
              name="paisId"
              defaultText="Selecione o país"
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              options={paises}
              width={"w-full"}
              label="País"
              errors={errors}
              required
            />
            <Dropdown
              name="estadoId"
              defaultText="Selecione o estado"
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              options={estados}
              width={"w-full"}
              label="Estado"
              errors={errors}
              required
              readonly={!Boolean(getValues("paisId"))}
            />
            <Dropdown
              name="cidadeId"
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              defaultText="Selecione a cidade"
              options={cidades}
              width={"w-full"}
              label="Cidade"
              errors={errors}
              required
              readonly={
                !(
                  Boolean(getValues("paisId")) && Boolean(getValues("estadoId"))
                )
              }
            />
          </div>
        </div>
      </IndexBody>
      <IndexFooter
        width={"w-[65%]"}
        className="flex items-center justify-between px-11"
      >
        <CancelarButton
          wide
          watch={watch}
          reset={cancelForm}
          getValues={getValues}
          voltar={() => navigate("/divisao/listagem")}
        />
        <Button
          text="Salvar"
          type="submit"
          colorClass={ButtonClasses.DarkGreen}
        />
      </IndexFooter>
    </form>
  );
};

export default CadastroDivisao;
