import axios from "axios";

const getServer = () => process.env.REACT_APP_API_URL;

const httpMethod = {
  Get: "get",
  Post: "post",
  Put: "put",
  Delete: "delete",
};

const toQueryString = (data) => {
  if (!data) return "";

  let params = [];
  let keys = Object.keys(data).filter(
    (key) => data[key] !== undefined && data[key] !== null && data[key] !== ""
  );

  for (var key of keys) {
    if (Array.isArray(data[key])) {
      // eslint-disable-next-line no-loop-func
      data[key].map((item) => params.push(`${key}=${item}`));
      continue;
    }

    params.push(`${key}=${data[key]}`);
  }

  return params.length ? "?" + params.join("&") : "";
};

const baseHttpRequest = async (method, url, query, data, config) => {
  let server = getServer();
  try {
    const response = await axios({
      method: method,
      ...config,
      url: server + url + toQueryString(query),
      data: data,
    });
    return response;
  } catch (error) {
    return {
      hasError: true,
      isUnathorized:
        error.response.status === 401 || error.response.status === 403,
      error,
    };
  }
};

const api = {
  get: async (url, query, token) => {
    const lng = window.localStorage.i18nextLng;
    let config = {
      "content-type": "text/json",
      headers: {},
    };

    if (token) {
      config.headers.Token = token;
    }

    config.headers.Lang = lng;

    return baseHttpRequest(httpMethod.Get, url, query, null, config);
  },
  put: async (url, data, token) => {
    const lng = window.localStorage.i18nextLng;
    let config = {
      headers: {},
    };

    if (token) {
      config.headers.Token = token;
    }

    config.headers.Lang = lng;

    return baseHttpRequest(httpMethod.Put, url, null, data, config);
  },
  post: async (url, data, token) => {
    const lng = window.localStorage.i18nextLng;
    let config = {
      headers: {},
    };

    if (token) {
      config.headers.Token = token;
    }

    config.headers.Lang = lng;

    return baseHttpRequest(httpMethod.Post, url, null, data, config);
  },
  delete: async (url, data, token) => {
    const lng = window.localStorage.i18nextLng;
    let config = {
      headers: {},
    };

    if (token) {
      config.headers.Token = token;
    }

    config.headers.Lang = lng;

    return baseHttpRequest(httpMethod.Delete, url, null, data, config);
  },
};

export default api;
