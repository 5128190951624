import Container from "./Container";

const IndexCadastro = ({
  width,
  height,
  texto,
  children,
  className,
  title = "Cadastro de Divisão",
}) => {
  return (
    <div className="flex flex-col items-center justify-center pt-[100px]">
      <div className={`${width} mb-4 text-[24px] font-semibold text-[#1B2646]`}>
        {title}
      </div>
      <Container width={width} height={height} texto={texto} custom={className}>
        {children}
      </Container>
    </div>
  );
};
export default IndexCadastro;
