import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import paisApi from "../../utility/api/paisApi";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Swal from "sweetalert2";
import { useCallback } from "react";
import ButtonClasses from "../../components/Button/typing";
import CancelarButton from "../../components/CancelarButton";
import { NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const CadastroPaises = ({ setIsVisible, onFinished }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  let isAllowed = useAthorize(
    permissoes.localizacaoConsulta,
    permissoes.localizacaoFull
  );

  const token = useSelector(selectUserToken);

  const onSubmit = useCallback(
    async ({ nome }) => {
      let data = {
        nome
      };

      let result = await paisApi.cadastrarPais(data, token);

      if (result.hasError) {
        Swal.fire({
          title: "Erro!",
          text: "Algo deu errado",
          icon: "error",
        });
        return;
      }

      if (result.data.length === 0) {
        reset();
        onFinished?.();

        Swal.fire({
          title: "Sucesso!",
          text: "Cadastrado com sucesso",
          icon: "success",
        });
        return;
      }

      Swal.fire({
        title: "Erro!",
        text: result.data,
        icon: "error",
      });
    },
    [onFinished, reset, token]
  );

  var footer = (
    <>
      <CancelarButton
        watch={watch}
        reset={() =>
          Swal.fire({
            text: "Realmente deseja cancelar o cadastro?",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              reset();

              Swal.fire({
                text: "Cancelado com sucesso!",
                icon: "success",
              });
            }
          })
        }
        getValues={getValues}
        voltar={() => setIsVisible()}
      />

      <Button
        colorClass={ButtonClasses.DarkGreen}
        text="Salvar"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Modal
      setModal={setIsVisible}
      title="Cadastro de País"
      footer={footer}
    >
      <div className="w-full h-full flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-[86%]">
          <div className="grid grid-cols-1 gap-8">
            <Input
              label="País"
              name="nome"
              type="text"
              placeholder="Insira o nome do país"
              register={register}
              required
              errors={errors}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CadastroPaises;
