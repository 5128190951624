const MenuImage = ({ configMenu }) => {
  return (
    <div>
      {configMenu.map((menu, index) => (
        menu.visible ? <div key={index} className="py-8 flex justify-center">
          <button onClick={() => menu.action?.()} className="h-8">
            <img
              alt={menu.alt}
              src={menu.icon}
              width={menu.width}
              height={menu.height}
            />
          </button>
        </div> : null
      ))}
    </div>
  );
};
export default MenuImage;
