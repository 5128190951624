import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import { Outlet, Navigate } from "react-router-dom";
import useRequireLogin from "../../hooks/useRequireLogin";
import Header from "./Header";
import MenuLeft from "./MenuLeft";
import serviceApi from "../../utility/api/serviceApi";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const { isLoading, redirectPath } = useRequireLogin();
  const [urlPortal, setUrlPortal] = useState('');
  const { t } = useTranslation();

  const token = useSelector(selectUserToken);

  if (isLoading) {
    return <></>;
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  async function fetchData () {
    var result = await serviceApi.checkUserValgroup(token);
    if(result.data) {
      setUrlPortal('/service/listagem/');
    } else {
      setUrlPortal('/');
    }
  }

  fetchData();

  return (
    <div id="background-geral">
      <Header />
      <MenuLeft urlPortal={urlPortal}/>
      <div className="">
        <Outlet />
      </div>
      {urlPortal == "/service/listagem/" && 
        <div className="w-full h-7 fixed bottom-0 shadow-md flex justify-center items-center bg-black text-white text-base">{`${t('serviceAide.footerMessage')}`} <b className="pl-1">0800-7214051</b></div>
      }
    </div>
  );
};

export default Layout;
