import Select from 'react-select';

const TempDropdown = ({
  placeholder,
  options,
  value,
  name,
  onChange,
}) => {
  return <Select
    value={value}
    placeholder={placeholder}
    isMulti
    name={name}
    options={options}
    onChange={onChange}
    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#2a914722',
        primary: '#2a9147',
      },
    })}
  />
};

export default TempDropdown;