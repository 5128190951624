const Modal = ({
  setModal,
  children,
  title,
  footer,
  width = "w-[500px]",
  height = "h-[400px]",
  heightBody = "h-[64%]",
  top = "top-[50%]",
}) => {
  return (
    <div>
      <div
        className="fixed z-10 top-0 left-0 w-full h-full bg-[#69626271] flex justify-center items-center"
        onClick={() => setModal(false)}
      ></div>
      <div className="absolute py-6 top-0 left-1/2 -translate-x-1/2 z-20 flex items-center min-h-full">
        <div
          className={`${width} ${height} bg-white rounded-md`}
        >
          <div className="w-full h-16 rounded-t-md border-b-2 border-[#B3B3B3] flex items-center pl-8 font-semibold text-[#5A5A5A]">
            <div className="flex-1">{title}</div>
          </div>
          <div className={`w-full ${heightBody}`}>{children}</div>
          <div className="w-full h-20 border-t-2 rounded-b-md flex items-center justify-between px-6">
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
