import informacaoNaoCadastradaImgPT from "../../assets/imagens/informacao-nao-encontradaPTBR.png";
import informacaoNaoCadastradaImgEN from "../../assets/imagens/informacao-nao-encontradaEN.png";

const InformacaoNaoCadastrada = () => {
  var lng = localStorage.getItem("i18nextLng");
  return (
    <div className="w-full mt-10 flex justify-center">
      <img className="max-w-[60%]" alt="Informação não cadastrada" src={lng == "pt-BR" ? informacaoNaoCadastradaImgPT : informacaoNaoCadastradaImgEN} />
    </div>
  );
};

export default InformacaoNaoCadastrada;
