import { selectUserToken } from "../../features/user";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ListChamados from "./ListChamados";

export const Chamados = () => {
  const token = useSelector(selectUserToken);
  const { t } = useTranslation();

  const colunas = [
    {
      label: `${t('serviceAide.number')}`,
      prop: "numeroChamado",
      width: "10%",
    },
    {
      label: `${t('serviceAide.description')}`,
      prop: "descricao",
      width: "40%",
    },
    {
      label: `${t('serviceAide.creationDate')}`,
      prop: "dataCriacao",
      width: "16%"
    },
    {
      label: `${t('serviceAide.lastModified')}`,
      prop: "dataUltimaModificacao",
      width: "16%"
    }
  ];

  return (
    <div>
      <ListChamados
        showOptions={true}
        colunas={colunas}
        hasStatus={true}
        hasLinkServiceAide={true}
        customTableName={`${t('serviceAide.titleListTickets')}`}
        style={{width: 98 + '%', marginTop: 2 + '%'}}
      />
    </div>
  );
};
