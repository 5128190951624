import api from "./index";

const departamentoApi = {
  listarDepartamentos: async (data, token) => {
    return await api.get(`/Department/List`, data, token);
  },
  cadastrarDepartamento: async (data, token) => {
    return await api.post(`/Department/Register`, data, token);
  },
  atualizarDepartamento: async (data, token) => {
    return await api.put(`/Department/Edit/${data.id}`, data, token);
  },
  inativarDepartamento: async (id, token) => {
    return await api.put(`/Departamento/Inativar`, { DepartamentoId: id }, token);
  },
  ativarDepartamento: async (id, token) => {
    return await api.put(`/Departamento/Ativar`, { DepartamentoId: id }, token);
  },
  excluirDepartamento: async (id, token) => {
    return await api.delete(`/Department/Delete/${id}`, { DepartamentoId: id }, token);
  },
  buscarDepartamento: async (id, token) => {
    return await api.get(`/Department/GetById/${id}`, { id }, token);
  },
  buscarDivisoes: async (token) => {
    return await api.get(`/Divisao/ListarDropdown`, null, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Department/GetAllByDivision/${data.divisionId}`, data, token);
  },
};

export default departamentoApi;
