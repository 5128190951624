import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Input from "../../components/Input";
import LoginSlider from "../../components/LoginSlider";
import { Validator } from "../../utility/validations";
import accountApi from "../../utility/api/accountApi";
import { selectUserToken, userFirstAccess } from "../../features/user";
import useRequireLogin from "../../hooks/useRequireLogin";

const PrimeiroAcesso = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const [showAlert, setShowAlert] = useState(true);
  const [validations, setValidations] = useState([]);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const passwordValidator = new Validator.Builder()
    .required()
    .minLenght(14)
    .hasLowerCase()
    .hasUpperCase()
    .hasNumber()
    .hasSpecialCaracter()
    .build();

  useEffect(() => {
    if (showAlert) {
      Swal.fire({
        title: "Atenção!",
        text: "Como é seu primeiro acesso, você precisa atualizar a sua senha",
        icon: "info",
      });
      setShowAlert(false);
    }

    const subscription = watch(() => {
      setValidations(passwordValidator.getValidationList(getValues("senha")));
    });

    return () => subscription.unsubscribe();
  }, [getValues, passwordValidator, showAlert, watch]);

  const onSubmit = async ({ senha, confirmarSenha }) => {
    var result = await accountApi.primeiroAcesso(senha, confirmarSenha, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    if (result.data.length === 0) {
      dispatch(userFirstAccess());
      navigate("/");

      Swal.fire({
        title: "Sucesso!",
        text: "Senha atualizada com sucesso",
        icon: "success",
      });
      return;
    }

    Swal.fire({
      title: "Erro!",
      text: result.data,
      icon: "error",
    });
  };

  const { isLoading, redirectPath } = useRequireLogin();

  if (isLoading) {
    return <></>;
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <div className="w-full h-screen m-f1:flex m-f1:flex-col d-f1:flex-row overflow-y-scroll d-f1:overflow-hidden">
      <div className="grow min-w-[50%] m-f1:h-[100%] m-f1:flex m-f1:justify-center d:h-screen ">
        <div className="w-full py-10 flex flex-col justify-center items-center">
          <div className="background-logo m-f1:w-[50%] m-f1:h-[30px] m-f2:w-[60%] m-f2:h-[42px] m-f1:flex m-f1:justify-center d-f1:hidden">
            <div className="m-f1:text-[#1D284A] m-f1:font-semibold m-f1:text-[20px] m-f2:text-[30px] m-f1:mt-10 m-f2:mt-20">
              Acessar
            </div>
          </div>
          <div className="m-f1:hidden d-f1:flex d-f1:w-full d-f1:items-center d-f1:px-[150px] d-f1:flex-col">
            <div className="background-logo w-full h-[62px] mb-12"></div>
            <div className="flex justify-center items-center">
              <div className="text-[#1D284A] font-semibold text-[30px] pr-4">
                Primeiro Acesso
              </div>
            </div>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col m-f1:mt-2 d-f1:mt-6 m-f2:mt-4 m-f1:px-6 m-f3:px-12 l:px-72 d-f1:px-36 d-f4:px-64 d-f5:px-[400px] t-f1:px-32">
              <Input
                label="Nova Senha"
                name="senha"
                type="password"
                placeholder="**************"
                register={register}
                validate={(text) => passwordValidator.validate(text)}
              />
              <div className="pt-1">
                {validations.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      item.isOk ? "text-green-500" : "text-red-500"
                    } font-medium text-[13px] flex`}
                  >
                    <div className={`${item.isOk ? "flex" : "hidden"}`}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.854 3.646C13.9006 3.69244 13.9375 3.74762 13.9627 3.80837C13.9879 3.86911 14.0009 3.93423 14.0009 4C14.0009 4.06577 13.9879 4.13089 13.9627 4.19163C13.9375 4.25238 13.9006 4.30755 13.854 4.354L6.85402 11.354C6.80758 11.4006 6.7524 11.4375 6.69166 11.4627C6.63091 11.4879 6.56579 11.5009 6.50002 11.5009C6.43425 11.5009 6.36913 11.4879 6.30839 11.4627C6.24764 11.4375 6.19247 11.4006 6.14602 11.354L2.64602 7.854C2.55213 7.76011 2.49939 7.63277 2.49939 7.5C2.49939 7.36722 2.55213 7.23989 2.64602 7.146C2.73991 7.05211 2.86725 6.99937 3.00002 6.99937C3.1328 6.99937 3.26013 7.05211 3.35402 7.146L6.50002 10.293L13.146 3.646C13.1925 3.59944 13.2476 3.56249 13.3084 3.53729C13.3691 3.51208 13.4343 3.49911 13.5 3.49911C13.5658 3.49911 13.6309 3.51208 13.6917 3.53729C13.7524 3.56249 13.8076 3.59944 13.854 3.646V3.646Z"
                          fill="#187733"
                        />
                      </svg>
                    </div>
                    <div className={`${item.isOk ? "hidden" : "flex"}`}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.64604 4.646C4.69248 4.59944 4.74766 4.56249 4.80841 4.53729C4.86915 4.51208 4.93427 4.49911 5.00004 4.49911C5.06581 4.49911 5.13093 4.51208 5.19167 4.53729C5.25242 4.56249 5.30759 4.59944 5.35404 4.646L8.00004 7.293L10.646 4.646C10.6925 4.59951 10.7477 4.56264 10.8085 4.53748C10.8692 4.51232 10.9343 4.49937 11 4.49937C11.0658 4.49937 11.1309 4.51232 11.1916 4.53748C11.2524 4.56264 11.3076 4.59951 11.354 4.646C11.4005 4.69249 11.4374 4.74768 11.4626 4.80842C11.4877 4.86916 11.5007 4.93426 11.5007 5C11.5007 5.06574 11.4877 5.13085 11.4626 5.19158C11.4374 5.25232 11.4005 5.30751 11.354 5.354L8.70704 8L11.354 10.646C11.4005 10.6925 11.4374 10.7477 11.4626 10.8084C11.4877 10.8692 11.5007 10.9343 11.5007 11C11.5007 11.0657 11.4877 11.1308 11.4626 11.1916C11.4374 11.2523 11.4005 11.3075 11.354 11.354C11.3076 11.4005 11.2524 11.4374 11.1916 11.4625C11.1309 11.4877 11.0658 11.5006 11 11.5006C10.9343 11.5006 10.8692 11.4877 10.8085 11.4625C10.7477 11.4374 10.6925 11.4005 10.646 11.354L8.00004 8.707L5.35404 11.354C5.30755 11.4005 5.25236 11.4374 5.19162 11.4625C5.13088 11.4877 5.06578 11.5006 5.00004 11.5006C4.9343 11.5006 4.86919 11.4877 4.80846 11.4625C4.74772 11.4374 4.69253 11.4005 4.64604 11.354C4.59955 11.3075 4.56267 11.2523 4.53752 11.1916C4.51236 11.1308 4.49941 11.0657 4.49941 11C4.49941 10.9343 4.51236 10.8692 4.53752 10.8084C4.56267 10.7477 4.59955 10.6925 4.64604 10.646L7.29304 8L4.64604 5.354C4.59948 5.30756 4.56253 5.25238 4.53733 5.19163C4.51212 5.13089 4.49915 5.06577 4.49915 5C4.49915 4.93423 4.51212 4.86911 4.53733 4.80837C4.56253 4.74762 4.59948 4.69245 4.64604 4.646Z"
                          fill="#FF0000"
                        />
                      </svg>
                    </div>
                    {item.message}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col m-f1:mt-2 d-f1:mt-6 m-f2:mt-4 m-f1:px-6 m-f3:px-12 l:px-72 d-f1:px-36 d-f4:px-64 d-f5:px-[400px] t-f1:px-32">
              <Input
                label="Confirmar Senha"
                name="confirmarSenha"
                type="password"
                placeholder="**************"
                register={register}
                validate={(text) =>
                  getValues("senha") === text
                    ? undefined
                    : "As senhas não conferem"
                }
                errors={errors}
              />
            </div>
            <div className="w-full flex justify-center items-center m-f2:mt-10">
              <button className="bg-[#1D284A] hover:bg-[#1E3784] m-f1:py-2 m-f2:py-3 m-f1:px-8 m-f2:px-14 text-[#FFF] rounded-md text-[12px]">
                Acessar
              </button>
            </div>
          </form>
        </div>
      </div>
      <LoginSlider />
    </div>
  );
};

export default PrimeiroAcesso;
