import api from "./index";

const perfilAcessoApi = {
  listarPerfisAcessos: async (data, token) => {
    return await api.get(`/Profile/List`, data, token);
  },
  cadastrarPerfilAcesso: async (data, token) => {
    return await api.post(`/Profile/Register`, data, token);
  },
  atualizarPerfilAcesso: async (data, token) => {
    return await api.put(`/Profile/Edit`, data, token);
  },
  inativarPerfilAcesso: async (id, token) => {
    return await api.put(`/Profile/Deactivate`, { id }, token);
  },
  ativarPerfilAcesso: async (id, token) => {
    return await api.put(`/Profile/Activate`, { id }, token);
  },
  excluirPerfilAcesso: async (id, token) => {
    return await api.delete(`/Profile/Delete`, { id }, token);
  },
  buscarPerfilAcesso: async (id, token) => {
    return await api.get(`/Profile/Search`, { id }, token);
  },
  buscarDivisoes: async (token) => {
    return await api.get(`/Divisao/ListarDropdown`, null, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/PerfilAcesso/ListarDropdown`, data, token);
  },
  listarDropdownSearch: async (data, token) => {
    return await api.get(`/Profile/ListProfileDropdown`, data, token);
  },
  buscarPerfisDropdown: async (data, token) => {
    return await api.get(`/PerfilAcesso/BuscarPerfisDropdown`, data, token);
  },
};

export default perfilAcessoApi;
