import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback, useMemo } from "react";
import useQuery from "../../utility/useQuery";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useConsulta = (token, config) => {
  const { t } = useTranslation();
  let query = useQuery();
  let pagina = parseInt(query.get("page") ?? 0);

  const form = useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState({
    quantidadeItems: 5,
    quantidadeTotal: 5,
    cards: [],
  });

  const showError = useCallback((result) => {
    if(typeof result.error.response != 'undefined'){
      let notification = result.error.response?.data?.notifications?.[0];

      Swal.fire({
        title: notification ? "Atenção!" : "Erro!",
        text: notification || "Algo deu errado",
        icon: notification ? "info" : "error",
      });
    }
  }, []);

  const onSearch = useCallback(
    async (onFinished) => {
      if (!token) return;
      // setIsLoading(true);

      let filtros = {
        ...form.getValues(),
        pagina: pagina,
        quantidadeItems: items.quantidadeItems,
      };

      let response = await config.onSearch.fetchFunction(filtros, token);

      setTimeout(() => {
        setIsLoading(false);
      }, 250);

      if (response.hasError) {
        if (!response.isUnathorized)
          Swal.fire({
            title: "Erro!",
            text: "Algo deu errado",
            icon: "error",
          });
      } else {
        setItems(response.data);

        let quantidadeMaximaPaginas =
          response.data.quantidadeTotal / response.data.quantidadeItems;

        if (quantidadeMaximaPaginas < pagina) navigate("?page=0");

        onFinished?.();
      }
    },
    [token, form, pagina, items.quantidadeItems, config.onSearch, navigate]
  );

  useEffect(() => {
    onSearch();

    const subscription = form.watch(() => onSearch());
    return () => subscription.unsubscribe();
  }, [onSearch, form.watch, form]);

  const events = useMemo(() => {
    return {
      ...config,
      onSearch,
      onAtivar: !config.onAtivar
        ? null
        : async (item, onSearch) => {
            let result = await config.onAtivar.fetchFunction(item.id, token);

            if (result.hasError) {
              showError(result);
            } else {
              (config.onAtivar.successFunction &&
                config.onAtivar.successFunction(item, onSearch)) ||
                onSearch(() => {
                  Swal.fire({
                    title: `${t('modalUser.success')}`,
                    text: config.onAtivar.successText,
                    icon: "success",
                  });
                });
            }
          },
      onInativar: !config.onInativar
        ? null
        : async (item, onSearch) => {
            let result = await config.onInativar.fetchFunction(item.id, token);

            if (result.hasError) {
              showError(result);
            } else {
              config.onInativar.successFunction
                ? config.onInativar.successFunction(item, onSearch)
                : onSearch(() => {
                    Swal.fire({
                      title: `${t('modalUser.success')}`,
                      text: config.onInativar.successText,
                      icon: "success",
                    });
                  });
            }
          },
      onExcluir: !config.onExcluir
        ? null
        : async (item, onSearch, limparFiltros) => {
            Swal.fire({
              title: config.onExcluir.questionText,
              showDenyButton: true,
              confirmButtonText: `${t('modalUser.yes')}`,
              denyButtonText: `${t('modalUser.no')}`,
            }).then(async (result) => {
              if (result.isConfirmed) {
                result = await config.onExcluir.fetchFunction(item.id, token);

                if (result.hasError) {
                  showError(result);
                } else {
                  if (result.data.length === 0 || result.data?.isValid) {
                    limparFiltros();
                    onSearch(() => {
                      Swal.fire({
                        text: config.onExcluir.successText,
                        title: `${t('modalUser.success')}`,
                        icon: "success",
                      });
                    });
                  } else {
                    Swal.fire({
                      title: "Erro!",
                      text: result.data,
                      icon: "error",
                    });
                  }
                }
              }
            });
          },
    };
  }, [config, onSearch, showError, token, t]);

  return {
    isLoading,
    form,
    items,
    pagina,
    events,
  };
};

export default useConsulta;
