import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClick } from "../../components/Dropdown/useClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function FilterChamados({ getChamados, showForm, statusList, searchText, setSearchText, selectedOption, setSelectedOption }) {
    const { t } = useTranslation();
    const [isVisible, setVisibility] = useState(false);
    const [options, setOptions] = useState()

    const content = useRef(null);
    const navigate = useNavigate();

    useClick(content, isVisible, setVisibility);

    useEffect(() => {
        if(typeof statusList != 'undefined' && statusList != null) {
            let list = [{"value": null, "text": "Todos"}];
            statusList.map(status => { 
                    list.push({"value": status, "text": status}) 
                }
            )
            setOptions(list)
        }

        if(selectedOption != null && typeof selectedOption != 'undefined') {
            if(typeof selectedOption.value != 'undefined') {
                setSelectedOption(selectedOption)
            } else {
                setSelectedOption({"value": selectedOption, "text": selectedOption})
            }
        }  
        
        if(selectedOption == null || selectedOption == '' || selectedOption?.value == ''){
            setSelectedOption({"value": null, "text": "Todos"})
        }
    }, [])

    function onSearchChange(search) {
        setSearchText(search)
    }

    function onSelectChange(option) {        
        setSelectedOption(option);
        setVisibility(false);
    }

    function fetchChamados() {
        let data = {
            id: "Chamados TI Portal ServiceAide",
            pagina: 0,
            quantidadeItems: 5,
            queryNome: searchText.length > 0 ? searchText : null,
            queryStatus: selectedOption != null ? selectedOption.value : null
        }

        saveCache(data)
        getChamados(data)
        navigate("/service/listagem");

    }

    function saveCache(data){
        window.localStorage.setItem('cacheQueryChamados', JSON.stringify(data))
    }

    return (
        <>
            {showForm &&
                <form
                    className="w-full border bg-white text-[#FFF] mt-10 rounded-lg m-f1:flex-col t-f1:flex-row m-f1:px-[4%] t-f1:px-0 m-f1:pt-[4%] t-f1:pt-0 t-f1:h-[150px] m-f1:h-[270px]"
                >
                    <div className="xl:flex justify-center flex-col h-full">
                        <div className="flex items-center">
                            <div className="flex m-f1:w-full t-f1:w-[30%] m-f1:mr-0 t-f1:mr-[5%] t-f1:ml-[4%] t-f1:pt-0 m-f1:pt-[3%]">
                                <div className="w-full flex flex-col h-16">
                                    <div className="text-[14px] text-[#8A92A6]">{`${t('serviceAide.search')}`}</div>
                                    <div className="text-[14px]">
                                        <input
                                            className="w-full pl-3 border border-[#686868] rounded-md py-3 text-black"
                                            placeholder={`${t('serviceAide.placeholdeSearch')}`}
                                            onChange={(event) => { onSearchChange(event.target.value) }}
                                            value={searchText}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-[30%] mr-[5%] flex">
                                <div className="w-full flex flex-col h-16">
                                    <div className="text-[14px] text-[#8A92A6]">{'Status'}</div>
                                    <div>
                                        <div className="relative" ref={content} >
                                            <button
                                                type="button"
                                                onClick={() => setVisibility(true)}
                                                className={"border-gray-700 relative flex justify-between items-center pl-3 py-3 font-semibold text-white  border  rounded-md  focus:outline-none focus:shadow-outline w-full"}
                                            >
                                                <div className="text-[#8A92A6] font-normal text-[12px]">
                                                    {selectedOption != null ? selectedOption.text : "Filtre pelo Status"}
                                                </div>
                                                <svg
                                                    className="ml-2 h-6 w-6 text-[#BBB]"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" />
                                                </svg>
                                            </button>
                                            {isVisible && (
                                                <div
                                                    className={`"top-full flex-col absolute flex left-0 w-full text-left font-normal z-10`}
                                                >
                                                    <div className={`max-h-[200px] rounded-md bg-white ring-1 ring-black ring-opacity-5 shadow-lg overflow-y-auto`}>
                                                        <ul className="text-black cursor-pointer">
                                                            {options?.map((item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className={`${selectedOption?.value === item.value || selectedOption === item.value
                                                                        ? "bg-[#187733] text-white"
                                                                        : "text-gray-700 hover:bg-slate-100"
                                                                        } block px-4 py-3 text-sm border-b `}
                                                                    onClick={() => { onSelectChange(item) }}
                                                                >
                                                                    {item.text}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-8">
                                <a
                                    className="bg-[#005BBF] cursor-pointer text-[#FFF] py-2 px-10 rounded-md hover:bg-[#1E3784]"
                                    onClick={() => fetchChamados()}
                                >
                                    <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        className="text-md mt-1 mr-2 text-white cursor-pointer"
                                    />
                                    {t('pageUser.buttons.filter.buttonTitle')}
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}