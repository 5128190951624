import React from "react";
import { Link } from "react-router-dom";


import LogoValgroupColorido from "../../../assets/imagens/png/logo-valgroup.png";

const MenuHeader = () => {
    return (
        <div className="flex py-3 px-3">
            <Link to="/">
                <img
                    className="max-h-[33px]"
                    alt="Logo Valgroup"
                    src={LogoValgroupColorido}
                />
            </Link>
        </div>
    )
}

export default MenuHeader;