import { useNavigate, useParams } from "react-router-dom";

const Index = () => {
    var lng = localStorage.getItem("i18nextLng");
    var navigate = useNavigate();
    var info = lng === "pt-BR" ? "Resultado não encontrado." : "Results not found.";
    var buttonVoltar = lng === "pt-BR" ? "Voltar" : "Back";
    return(
        <div className="w-100 h-screen flex flex-col justify-center items-center">
            {info}
            <button onClick={() => navigate(`/service/listagem/`)} className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none mt-2">{buttonVoltar}</button>
        </div>
    );
}
export default Index;