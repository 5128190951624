import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import permissaoApi from "../../utility/api/permissaoApi";
import moduloApi from "../../utility/api/moduloApi";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Swal from "sweetalert2";
import { useCallback, useEffect, useState } from "react";
import Dropdown from "../../components/Dropdown";
import ButtonClasses from "../../components/Button/typing";
import CancelarButton from "../../components/CancelarButton";
import { NotAllowed, permissoes, useAthorize } from "../../hooks/useAthorize";

const CadastroPermissao = ({ setIsVisible, onFinished }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  let isAllowed = useAthorize(
    permissoes.usuarioConsulta,
    permissoes.usuarioFull
  );

  const token = useSelector(selectUserToken);
  const [modulos, setModulos] = useState([]);

  const onSubmit = useCallback(
    async ({ moduloId, nome, nomeAlternativo, descricao }) => {
      let data = {
        moduloId: parseInt(moduloId),
        nome,
        nomeAlternativo,
        descricao,
      };

      let result = await permissaoApi.cadastrarPermissao(data, token);

      if (result.hasError) {
        Swal.fire({
          title: "Erro!",
          text: "Algo deu errado",
          icon: "error",
        });
        return;
      }

      if (result.data.length === 0) {
        reset();
        onFinished?.();

        Swal.fire({
          title: "Sucesso!",
          text: "Cadastrado com sucesso",
          icon: "success",
        });
        return;
      }

      Swal.fire({
        title: "Erro!",
        text: result.data,
        icon: "error",
      });
    },
    [onFinished, reset, token]
  );

  const fetchModulos = useCallback(async () => {
    var result = await moduloApi.listarDropdown({}, token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      return;
    }

    setModulos(result.data);
  }, [token]);

  useEffect(() => {
    fetchModulos();
  }, [fetchModulos]);

  var footer = (
    <>
      <CancelarButton
        watch={watch}
        reset={() =>
          Swal.fire({
            text: "Realmente deseja cancelar o cadastro?",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: `Não`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let values = getValues();

              Object.getOwnPropertyNames(values).forEach(function (prop) {
                values[prop] = "";
              });

              reset(values);

              Swal.fire({
                text: "Cancelado com sucesso!",
                icon: "success",
              });
            }
          })
        }
        getValues={getValues}
        voltar={() => setIsVisible()}
      />
      <Button
        colorClass={ButtonClasses.DarkGreen}
        text="Salvar"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );

  if (!isAllowed) return <NotAllowed />;

  return (
    <Modal
      setModal={setIsVisible}
      title="Cadastro de Permissão"
      footer={footer}
      height="h-auto"
      heightBody="h-[64%] py-6"
    >
      <div className="w-full h-full flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-[86%]">
          <div className="grid grid-cols-1 gap-6">
            <Dropdown
              name="moduloId"
              defaultText="Selecione o Módulo"
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              options={modulos}
              width={"w-full"}
              label="Módulo"
              errors={errors}
              required
            />

            <Input
              label="Nome"
              name="nome"
              type="text"
              placeholder="Insira o nome da permissão"
              register={register}
              required
              errors={errors}
            />

            <Input
              label="Nome Alterantivo"
              name="nomeAlternativo"
              type="text"
              placeholder="Insira o nome alternativo da permissão"
              register={register}
              required
              errors={errors}
            />

            <Input
              label="Descrição"
              name="descricao"
              type="text"
              placeholder="Insira a descrição da permissão"
              register={register}
              errors={errors}
              multiline
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CadastroPermissao;
