const initialState = [];

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "filters/update": {
      let filterIndex = state.findIndex(f => f.id === action.payload.id);
      if (filterIndex !== -1) {
        const mutatedState = [...state];
        mutatedState[filterIndex] = {...action.payload};
        return mutatedState;
      }
      return [...state, action.payload];
    }
    default:
      return state;
  }
}
