import api from "./index";

const serviceApi = {
  listarServicos: async (token) => {
    var result = await api.get(`/api/ServiceAide/ServicesParent`, null, token);
    return result
  },
  listarServicosFilho: async (parentId , token) => {
    var result = await api.get(`/api/ServiceAide/ServicesChildren/${parentId}`, parentId, token);
    return result
  },
  filtroService: async (termo , token) => {
    return await api.get(`/api/ServiceAide/ServicePesquisar/${termo}`, termo, token);
  },
  checkUserValgroup: async (token) => {
    var result = await api.get(`/api/ServiceAide/UsuarioValgroup`, null, token);
    return result
  },
  listarChamados: async (data, token) => {
    var result = await api.get(`/api/ServiceAide/ServicosChamados`, data, token);
    return result;
  },
  clickService: async (servicoId, token) => {
    var result = await api.get(`/api/ServiceAide/IncrementarCliquesServico/${servicoId}`, servicoId, token);
    return result;
  },
};

export default serviceApi;
