import { useEffect, useState } from "react";
import { shallowObjectComparison } from "../utility/util";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const CancelarButton = ({ watch, reset, getValues, voltar, wide }) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState(undefined);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (!initialData) {
      setInitialData(getValues());
    }

    const subscription = watch((values, { name, type }) => {
      let newValues = getValues();

      // O name e o type retorna como undefined quando é realizado o reset
      if (name === undefined && type === undefined) {
        setInitialData(values);
        setHasData(false);
      } else {
        setHasData(!shallowObjectComparison(newValues, initialData));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [getValues, initialData, watch]);

  useEffect(() => {
    setHasData(!shallowObjectComparison(getValues(), initialData));
  }, [getValues, initialData]);

  if (hasData) {
    return (
      <Button
        className={`${wide ? "!w-[181px]" : undefined}`}
        type="button"
        onClick={() => {
          reset();
        }}
      >
        {t('components.buttons.cancel')}
      </Button>
    );
  }

  return (
    <Button
      className={`${wide ? "!w-[181px]" : undefined}`}
      type="button"
      onClick={() => voltar?.()}
    >
      {t('components.buttons.back')}
    </Button>
  );
};

export default CancelarButton;
