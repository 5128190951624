export const userLoggedIn = (user) => {
  return {
    type: "user/logged-in",
    payload: user,
  };
};

export const userUpdated = (user) => {
  return {
    type: "user/updated",
    payload: user,
  };
};

export const userFirstAccess = () => {
  return {
    type: "user/first-access",
  };
};

export const userLoggedOut = () => {
  return {
    type: "user/logged-out",
  };
};

export const selectUserId = (state) => state.user.id;
export const selectUserToken = (state) => state.user.token;
export const selectUser = (state) => state.user;
