const Container = ({ width, height, texto, children, custom }) => {
  return (
    <div
      className={`${width} ${height} py-4 bg-white rounded-md border border-[#ECECEC] ${custom}`}
    >
      {texto || children}
    </div>
  );
};
export default Container;
