import api from "./index";

const paisApi = {
  listarPaises: async (data, token) => {
    return await api.get(`/Pais/Listar`, data, token);
  },
  cadastrarPais: async (data, token) => {
    return await api.post(`/Pais/Cadastrar`, data, token);
  },
  atualizarPais: async (data, token) => {
    return await api.put(`/Pais/Editar`, data, token);
  },
  buscarPais: async (PaisId, token) => {
    return await api.get(`/Pais/Buscar`, { PaisId }, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Pais/ListarDropdown`, data, token);
  },
};

export default paisApi;
