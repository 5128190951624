import api from "./index";

const cidadeApi = {
  listarCidades: async (data, token) => {
    return await api.get(`/Cidade/Listar`, data, token);
  },
  cadastrarCidade: async (data, token) => {
    return await api.post(`/Cidade/Cadastrar`, data, token);
  },
  atualizarCidade: async (data, token) => {
    return await api.put(`/Cidade/Editar`, data, token);
  },
  buscarCidade: async (CidadeId, token) => {
    return await api.get(`/Cidade/Buscar`, { CidadeId }, token);
  },
  listarDropdown: async (data, token) => {
    return await api.get(`/Cidade/ListarDropdown`, data, token);
  },
};

export default cidadeApi;
