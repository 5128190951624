import buttonClasses from "./typing";

const Button = ({
  colorClass = buttonClasses.lightGreen,
  type = "button",
  text,
  children,
  width = "w-[157px]",
  height = "h-[44px]",
  className,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={`${colorClass} ${width} ${height} ${className}`}
      onClick={() => onClick?.()}
    >
      {text || children}
    </button>
  );
};
export default Button;
